@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,600');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Montserrat';
  background-color: black;
}

.fadein {
  background-color: black;
}

.fadein .image {
  /* position:absolute;
  top:0; */
  -webkit-animation-name: fade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 10s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 10s;
  animation-direction: alternate;
  cursor: none;
}

@-webkit-keyframes fade {
  0% {opacity: 0;}
  35% {opacity: 1;}
  95% {opacity: 1;}
  100% {opacity: 0;}
}
@keyframes fade {
  0% {opacity: 0;}
  35% {opacity: 1;}
  95% {opacity: 1;}
  100% {opacity: 0;}
}

.formControl {
  margin: 0 auto;
  width: 200px;
}

.imageBox {
  display: grid;
  width: 100%;
}

.image {
  width: 100%;
  /* max-height: 100%; */
  margin: auto;
}

.lyrics {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  /* line-height: 65px; */
  z-index: 10;
  opacity: 1;
  left: 0;
  right: 0;

  text-align: center;
  color: black;
  font-family: Montserrat;
  margin-bottom: -30px;
  -webkit-animation: AnimationName 15s ease infinite;
  -moz-animation: AnimationName 15s ease infinite;
  -o-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-size: 100%; */
  background-size: 400% 400%;
}

@media only screen and (max-width: 922px) {
  .lyrics {
    font-size: 25px;
  }
}

@media only screen and (min-width: 922px) {
  .lyrics {
    font-size: 50px;
  }
}

.lyrics-shadow-top {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  z-index: 9;
  opacity: 1;
  left: 0;
  right: 0;
  /* top: 49%; */

  text-align: center;
  color: black;
  font-family: Montserrat;
  /* font-size: 50px; */
  margin-bottom: -60px; 
  text-shadow: 0px 0px black;
  
}

@media only screen and (max-width: 922px) {
  .lyrics-shadow-top {
    font-size: 25px;
  }
}

@media only screen and (min-width: 922px) {
  .lyrics-shadow-top {
    font-size: 50px;
  }
}

.lyrics-shadow-bottom {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  z-index: 9;
  opacity: 1;
  left: 0;
  right: 0;

  text-align: center;
  color: black;
  font-family: Montserrat;
  /* font-size: 50px; */
  margin-bottom: -60px; 
  text-shadow: 1px 2px black;
}

@media only screen and (max-width: 922px) {
  .lyrics-shadow-bottom {
    font-size: 25px;
  }
}

@media only screen and (min-width: 922px) {
  .lyrics-shadow-bottom {
    font-size: 50px;
  }
}

canvas {
  position: absolute;
  top: 15%;
  z-index: 2;
}

.picker-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.lyrics-background {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 49%;
  opacity: 0.45;
  width: 1200px;
  background-color: white;
  z-index: 6;
}

@media only screen and (max-width: 922px) {
  .lyrics-background {
    height: 290px;
  }
}


@media only screen and (min-width: 922px) {
  .lyrics-background {
    height: 500px;
  }
}

@media only screen and (min-width: 1200px) {
  .lyrics-background {
    height: 350px;
  }
}




.replay-countdown {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 49%;
  opacity: 1;
  width: 100px;
  height: 100px;
  background-color: white;
  z-index: 3;
  border-radius: 50%;
}

.replay-countdown-number {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 49%;
  opacity: 1;
  width: 100px;
  height: 100px;
  color: white;
  z-index: 4;
}

.hover-options {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
  height: 100px;
  width: 400px;
  display: block;
}

.hover-buttons-container {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  justify-content: flex-start;
}

.helper-container {
  animation: mymove 5s infinite;
  color: white;
}

@keyframes mymove {
  50% {font-size: 40px;}
}

.button {
  cursor: pointer;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 49%;
  opacity: 1;
  background-color: black;
  z-index: 4;
  width: 160px;
  border: 1px solid black;
  font-size: 16px;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 4px;
  color: white;
  background-color: darkgreen;
  text-decoration: none;
}

#overlay {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  /* background: linear-gradient(270deg, #ff00f5, #003eff, #ff3a00, #fffa00); */
  background-size: 400% 400%;
  -webkit-animation: AnimationName 15s ease infinite;
  -moz-animation: AnimationName 15s ease infinite;
  -o-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-o-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.greeting {
  margin: 0 auto;
  max-width: 567px;
  padding: 15px;
  color: white;
}

.footer {
  padding-bottom:40px;
}

.menu-button-container {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.container {
  max-width: 800px;
  text-align: center;
  max-height: 100%;
  margin: 0 auto;
  padding: 10px;
}

.container p {
  color: white!important;
}

.fine-art-logo {
  width: 80px;
  margin-top: 30px;
  /* margin-bottom: -70px; */
}

.container-paper {
  padding-left: 10%;
  padding-right: 10%;
  height: 100%;
}

.text-area {
  width: 260px;
  margin: 0 auto;
}

@import url(//fonts.googleapis.com/css?family=Vibur);

.container-paper {
  /* background: #112 url(//images.weserv.nl/?url=i.imgur.com/6QJjYMe.jpg)  center no-repeat; */
  background-size:cover;
  background: #112 url(//tinyurl.com/3kf3h8r3) center;
  /* margin:0 */
}
.logo {
  text-align: center;
  width: 85%;
  height: 100px;
  margin: auto;
  padding-top: 50px;
  /* position: absolute; */
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  user-select: none;
  font-size: 20px;
}

.logo b{
  font: 400 10vh "Vibur";
  color: #fee;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #ff4444, 0 0 0.5em #ff4444, 0 0 0.1em #ff4444, 0 10px 3px #000;
}

@media only screen and (max-width: 1030px) {
  .logo b {
    font: 400 5vh "Vibur";
  }
}

@media only screen and (min-width: 1030px) {
  .logo b {
    font: 400 10vh "Vibur";
  }
}

.logo b span{
  animation: blink linear infinite 2s;
}
.logo b span:nth-of-type(2){
  animation: blink linear infinite 3s;
}
@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79%{
     color: #333;
  }
  80% {
    
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}

/* .bpm-text-field {
  background-color: white;
} */

#song {
  background-color: white;
}

a {
  color: pink;
}

.formControl {
  background-color: white;
}

.text-field-wrapper {
  background-color: white;
  display: inline-block;
  border-radius: 10px;
  padding: 20px;
}

.lyrics-switch-container {
  display: inline-block;
  background-color: white;
  padding: 15px;
  margin-top: 20px;
  border-radius: 10px;
}

.viz {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* opacity: 0.45; */
  opacity: 0.55;
}

.demo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 1%;
  opacity: 1;
  width: 90%;
  background-color: white;
  z-index: 3;
  border: 3px solid white;
}

.close-demo {
  left: 90%;
  color: white;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 1%;
  z-index: 4;
  cursor: pointer;
  font-size: 20px;
}

.support {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.support-images {
  display: flex;
  align-items: center;
  justify-content: center;
}

.support-image {
  /* width: 40px; */
  height: 40px;
  border-radius: 15px;
  margin-left: 10px;
}

.not-compatible {
  /* margin: auto; */
  width: 200px;
  height: 100px;
  text-align: center;
  flex-direction: column;
  display: flex;
  padding: 60px;
  justify-content: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.close-incompatible {
  left: 90%;
  color: black;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 1%;
  z-index: 4;
  cursor: pointer;
  font-size: 20px;
}