@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,600);
@import url(//fonts.googleapis.com/css?family=Vibur);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  font-family: 'Montserrat';
  background-color: black;
}

.fadein {
  background-color: black;
}

.fadein .image {
  /* position:absolute;
  top:0; */
  -webkit-animation-name: fade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 10s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 10s;
  animation-direction: alternate;
  cursor: none;
}

@-webkit-keyframes fade {
  0% {opacity: 0;}
  35% {opacity: 1;}
  95% {opacity: 1;}
  100% {opacity: 0;}
}
@keyframes fade {
  0% {opacity: 0;}
  35% {opacity: 1;}
  95% {opacity: 1;}
  100% {opacity: 0;}
}

.formControl {
  margin: 0 auto;
  width: 200px;
}

.imageBox {
  display: grid;
  width: 100%;
}

.image {
  width: 100%;
  /* max-height: 100%; */
  margin: auto;
}

.lyrics {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  /* line-height: 65px; */
  z-index: 10;
  opacity: 1;
  left: 0;
  right: 0;

  text-align: center;
  color: black;
  font-family: Montserrat;
  margin-bottom: -30px;
  -webkit-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-size: 100%; */
  background-size: 400% 400%;
}

@media only screen and (max-width: 922px) {
  .lyrics {
    font-size: 25px;
  }
}

@media only screen and (min-width: 922px) {
  .lyrics {
    font-size: 50px;
  }
}

.lyrics-shadow-top {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  z-index: 9;
  opacity: 1;
  left: 0;
  right: 0;
  /* top: 49%; */

  text-align: center;
  color: black;
  font-family: Montserrat;
  /* font-size: 50px; */
  margin-bottom: -60px; 
  text-shadow: 0px 0px black;
  
}

@media only screen and (max-width: 922px) {
  .lyrics-shadow-top {
    font-size: 25px;
  }
}

@media only screen and (min-width: 922px) {
  .lyrics-shadow-top {
    font-size: 50px;
  }
}

.lyrics-shadow-bottom {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  z-index: 9;
  opacity: 1;
  left: 0;
  right: 0;

  text-align: center;
  color: black;
  font-family: Montserrat;
  /* font-size: 50px; */
  margin-bottom: -60px; 
  text-shadow: 1px 2px black;
}

@media only screen and (max-width: 922px) {
  .lyrics-shadow-bottom {
    font-size: 25px;
  }
}

@media only screen and (min-width: 922px) {
  .lyrics-shadow-bottom {
    font-size: 50px;
  }
}

canvas {
  position: absolute;
  top: 15%;
  z-index: 2;
}

.picker-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.lyrics-background {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 49%;
  opacity: 0.45;
  width: 1200px;
  background-color: white;
  z-index: 6;
}

@media only screen and (max-width: 922px) {
  .lyrics-background {
    height: 290px;
  }
}


@media only screen and (min-width: 922px) {
  .lyrics-background {
    height: 500px;
  }
}

@media only screen and (min-width: 1200px) {
  .lyrics-background {
    height: 350px;
  }
}




.replay-countdown {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 49%;
  opacity: 1;
  width: 100px;
  height: 100px;
  background-color: white;
  z-index: 3;
  border-radius: 50%;
}

.replay-countdown-number {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 49%;
  opacity: 1;
  width: 100px;
  height: 100px;
  color: white;
  z-index: 4;
}

.hover-options {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
  height: 100px;
  width: 400px;
  display: block;
}

.hover-buttons-container {
  display: -webkit-flex;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.helper-container {
  -webkit-animation: mymove 5s infinite;
          animation: mymove 5s infinite;
  color: white;
}

@-webkit-keyframes mymove {
  50% {font-size: 40px;}
}

@keyframes mymove {
  50% {font-size: 40px;}
}

.button {
  cursor: pointer;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 49%;
  opacity: 1;
  background-color: black;
  z-index: 4;
  width: 160px;
  border: 1px solid black;
  font-size: 16px;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 4px;
  color: white;
  background-color: darkgreen;
  text-decoration: none;
}

#overlay {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  /* background: linear-gradient(270deg, #ff00f5, #003eff, #ff3a00, #fffa00); */
  background-size: 400% 400%;
  -webkit-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.greeting {
  margin: 0 auto;
  max-width: 567px;
  padding: 15px;
  color: white;
}

.footer {
  padding-bottom:40px;
}

.menu-button-container {
  display: -webkit-flex;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 40px;
}

.container {
  max-width: 800px;
  text-align: center;
  max-height: 100%;
  margin: 0 auto;
  padding: 10px;
}

.container p {
  color: white!important;
}

.fine-art-logo {
  width: 80px;
  margin-top: 30px;
  /* margin-bottom: -70px; */
}

.container-paper {
  padding-left: 10%;
  padding-right: 10%;
  height: 100%;
}

.text-area {
  width: 260px;
  margin: 0 auto;
}

.container-paper {
  /* background: #112 url(//images.weserv.nl/?url=i.imgur.com/6QJjYMe.jpg)  center no-repeat; */
  background-size:cover;
  background: #112 url(//tinyurl.com/3kf3h8r3) center;
  /* margin:0 */
}
.logo {
  text-align: center;
  width: 85%;
  height: 100px;
  margin: auto;
  padding-top: 50px;
  /* position: absolute; */
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 20px;
}

.logo b{
  font: 400 10vh "Vibur";
  color: #fee;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #ff4444, 0 0 0.5em #ff4444, 0 0 0.1em #ff4444, 0 10px 3px #000;
}

@media only screen and (max-width: 1030px) {
  .logo b {
    font: 400 5vh "Vibur";
  }
}

@media only screen and (min-width: 1030px) {
  .logo b {
    font: 400 10vh "Vibur";
  }
}

.logo b span{
  -webkit-animation: blink linear infinite 2s;
          animation: blink linear infinite 2s;
}
.logo b span:nth-of-type(2){
  -webkit-animation: blink linear infinite 3s;
          animation: blink linear infinite 3s;
}
@-webkit-keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79%{
     color: #333;
  }
  80% {
    
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}
@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79%{
     color: #333;
  }
  80% {
    
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}

/* .bpm-text-field {
  background-color: white;
} */

#song {
  background-color: white;
}

a {
  color: pink;
}

.formControl {
  background-color: white;
}

.text-field-wrapper {
  background-color: white;
  display: inline-block;
  border-radius: 10px;
  padding: 20px;
}

.lyrics-switch-container {
  display: inline-block;
  background-color: white;
  padding: 15px;
  margin-top: 20px;
  border-radius: 10px;
}

.viz {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* opacity: 0.45; */
  opacity: 0.55;
}

.demo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 1%;
  opacity: 1;
  width: 90%;
  background-color: white;
  z-index: 3;
  border: 3px solid white;
}

.close-demo {
  left: 90%;
  color: white;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 1%;
  z-index: 4;
  cursor: pointer;
  font-size: 20px;
}

.support {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: -10px;
}

.support-images {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.support-image {
  /* width: 40px; */
  height: 40px;
  border-radius: 15px;
  margin-left: 10px;
}

.not-compatible {
  /* margin: auto; */
  width: 200px;
  height: 100px;
  text-align: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
  padding: 60px;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.close-incompatible {
  left: 90%;
  color: black;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 1%;
  z-index: 4;
  cursor: pointer;
  font-size: 20px;
}

.kaleidoscope {
	width: 100%;
	height: 100%
}

.kaleidoscope .tile {
	display: none;
	position: absolute;
	top: 50%;
	left: 0;
	z-index: 0;
	overflow: hidden;
	height: 100%;
	width: 50%;
	-webkit-transform-origin: right top;
	transform-origin: right top
}

.kaleidoscope .tile .image {
	position: relative;
	top: 0;
	left: 100%;
	height: 100%;
	width: 100%;
	background-image: url(/static/media/butterfly-montage.fe58093d.jpeg);
	-webkit-transform-origin: left top;
	transform-origin: left top
}

.kaleidoscope.n0 .tile {
	display: block
}

.kaleidoscope.n0 .tile .image {
	-webkit-transform: rotate(Infinitydeg);
	transform: rotate(Infinitydeg)
}

.kaleidoscope.n1 .tile {
	display: block
}

.kaleidoscope.n1 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n1 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n1 .tile .image {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n2 .tile {
	display: block
}

.kaleidoscope.n2 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n2 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n2 .tile.t2 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n2 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n2 .tile .image {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.kaleidoscope.n3 .tile {
	display: block
}

.kaleidoscope.n3 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n3 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n3 .tile.t2 {
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg)
}

.kaleidoscope.n3 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(120deg);
	transform: scale(-1, 1) rotate(120deg)
}

.kaleidoscope.n3 .tile.t4 {
	-webkit-transform: rotate(240deg);
	transform: rotate(240deg)
}

.kaleidoscope.n3 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(240deg);
	transform: scale(-1, 1) rotate(240deg)
}

.kaleidoscope.n3 .tile .image {
	-webkit-transform: rotate(60deg);
	transform: rotate(60deg)
}

.kaleidoscope.n4 .tile {
	display: block
}

.kaleidoscope.n4 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n4 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n4 .tile.t2 {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.kaleidoscope.n4 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(90deg);
	transform: scale(-1, 1) rotate(90deg)
}

.kaleidoscope.n4 .tile.t4 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n4 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n4 .tile.t6 {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg)
}

.kaleidoscope.n4 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(270deg);
	transform: scale(-1, 1) rotate(270deg)
}

.kaleidoscope.n4 .tile .image {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg)
}

.kaleidoscope.n5 .tile {
	display: block
}

.kaleidoscope.n5 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n5 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n5 .tile.t2 {
	-webkit-transform: rotate(72deg);
	transform: rotate(72deg)
}

.kaleidoscope.n5 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(72deg);
	transform: scale(-1, 1) rotate(72deg)
}

.kaleidoscope.n5 .tile.t4 {
	-webkit-transform: rotate(144deg);
	transform: rotate(144deg)
}

.kaleidoscope.n5 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(144deg);
	transform: scale(-1, 1) rotate(144deg)
}

.kaleidoscope.n5 .tile.t6 {
	-webkit-transform: rotate(216deg);
	transform: rotate(216deg)
}

.kaleidoscope.n5 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(216deg);
	transform: scale(-1, 1) rotate(216deg)
}

.kaleidoscope.n5 .tile.t8 {
	-webkit-transform: rotate(288deg);
	transform: rotate(288deg)
}

.kaleidoscope.n5 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(288deg);
	transform: scale(-1, 1) rotate(288deg)
}

.kaleidoscope.n5 .tile .image {
	-webkit-transform: rotate(36deg);
	transform: rotate(36deg)
}

.kaleidoscope.n6 .tile {
	display: block
}

.kaleidoscope.n6 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n6 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n6 .tile.t2 {
	-webkit-transform: rotate(60deg);
	transform: rotate(60deg)
}

.kaleidoscope.n6 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(60deg);
	transform: scale(-1, 1) rotate(60deg)
}

.kaleidoscope.n6 .tile.t4 {
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg)
}

.kaleidoscope.n6 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(120deg);
	transform: scale(-1, 1) rotate(120deg)
}

.kaleidoscope.n6 .tile.t6 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n6 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n6 .tile.t8 {
	-webkit-transform: rotate(240deg);
	transform: rotate(240deg)
}

.kaleidoscope.n6 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(240deg);
	transform: scale(-1, 1) rotate(240deg)
}

.kaleidoscope.n6 .tile.t10 {
	-webkit-transform: rotate(300deg);
	transform: rotate(300deg)
}

.kaleidoscope.n6 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(300deg);
	transform: scale(-1, 1) rotate(300deg)
}

.kaleidoscope.n6 .tile .image {
	-webkit-transform: rotate(30deg);
	transform: rotate(30deg)
}

.kaleidoscope.n7 .tile {
	display: block
}

.kaleidoscope.n7 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n7 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n7 .tile.t2 {
	-webkit-transform: rotate(51.42857deg);
	transform: rotate(51.42857deg)
}

.kaleidoscope.n7 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(51.42857deg);
	transform: scale(-1, 1) rotate(51.42857deg)
}

.kaleidoscope.n7 .tile.t4 {
	-webkit-transform: rotate(102.85714deg);
	transform: rotate(102.85714deg)
}

.kaleidoscope.n7 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(102.85714deg);
	transform: scale(-1, 1) rotate(102.85714deg)
}

.kaleidoscope.n7 .tile.t6 {
	-webkit-transform: rotate(154.28571deg);
	transform: rotate(154.28571deg)
}

.kaleidoscope.n7 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(154.28571deg);
	transform: scale(-1, 1) rotate(154.28571deg)
}

.kaleidoscope.n7 .tile.t8 {
	-webkit-transform: rotate(205.71429deg);
	transform: rotate(205.71429deg)
}

.kaleidoscope.n7 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(205.71429deg);
	transform: scale(-1, 1) rotate(205.71429deg)
}

.kaleidoscope.n7 .tile.t10 {
	-webkit-transform: rotate(257.14286deg);
	transform: rotate(257.14286deg)
}

.kaleidoscope.n7 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(257.14286deg);
	transform: scale(-1, 1) rotate(257.14286deg)
}

.kaleidoscope.n7 .tile.t12 {
	-webkit-transform: rotate(308.57143deg);
	transform: rotate(308.57143deg)
}

.kaleidoscope.n7 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(308.57143deg);
	transform: scale(-1, 1) rotate(308.57143deg)
}

.kaleidoscope.n7 .tile .image {
	-webkit-transform: rotate(25.71429deg);
	transform: rotate(25.71429deg)
}

.kaleidoscope.n8 .tile {
	display: block
}

.kaleidoscope.n8 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n8 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n8 .tile.t2 {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg)
}

.kaleidoscope.n8 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(45deg);
	transform: scale(-1, 1) rotate(45deg)
}

.kaleidoscope.n8 .tile.t4 {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.kaleidoscope.n8 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(90deg);
	transform: scale(-1, 1) rotate(90deg)
}

.kaleidoscope.n8 .tile.t6 {
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg)
}

.kaleidoscope.n8 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(135deg);
	transform: scale(-1, 1) rotate(135deg)
}

.kaleidoscope.n8 .tile.t8 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n8 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n8 .tile.t10 {
	-webkit-transform: rotate(225deg);
	transform: rotate(225deg)
}

.kaleidoscope.n8 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(225deg);
	transform: scale(-1, 1) rotate(225deg)
}

.kaleidoscope.n8 .tile.t12 {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg)
}

.kaleidoscope.n8 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(270deg);
	transform: scale(-1, 1) rotate(270deg)
}

.kaleidoscope.n8 .tile.t14 {
	-webkit-transform: rotate(315deg);
	transform: rotate(315deg)
}

.kaleidoscope.n8 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(315deg);
	transform: scale(-1, 1) rotate(315deg)
}

.kaleidoscope.n8 .tile .image {
	-webkit-transform: rotate(22.5deg);
	transform: rotate(22.5deg)
}

.kaleidoscope.n9 .tile {
	display: block
}

.kaleidoscope.n9 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n9 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n9 .tile.t2 {
	-webkit-transform: rotate(40deg);
	transform: rotate(40deg)
}

.kaleidoscope.n9 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(40deg);
	transform: scale(-1, 1) rotate(40deg)
}

.kaleidoscope.n9 .tile.t4 {
	-webkit-transform: rotate(80deg);
	transform: rotate(80deg)
}

.kaleidoscope.n9 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(80deg);
	transform: scale(-1, 1) rotate(80deg)
}

.kaleidoscope.n9 .tile.t6 {
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg)
}

.kaleidoscope.n9 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(120deg);
	transform: scale(-1, 1) rotate(120deg)
}

.kaleidoscope.n9 .tile.t8 {
	-webkit-transform: rotate(160deg);
	transform: rotate(160deg)
}

.kaleidoscope.n9 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(160deg);
	transform: scale(-1, 1) rotate(160deg)
}

.kaleidoscope.n9 .tile.t10 {
	-webkit-transform: rotate(200deg);
	transform: rotate(200deg)
}

.kaleidoscope.n9 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(200deg);
	transform: scale(-1, 1) rotate(200deg)
}

.kaleidoscope.n9 .tile.t12 {
	-webkit-transform: rotate(240deg);
	transform: rotate(240deg)
}

.kaleidoscope.n9 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(240deg);
	transform: scale(-1, 1) rotate(240deg)
}

.kaleidoscope.n9 .tile.t14 {
	-webkit-transform: rotate(280deg);
	transform: rotate(280deg)
}

.kaleidoscope.n9 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(280deg);
	transform: scale(-1, 1) rotate(280deg)
}

.kaleidoscope.n9 .tile.t16 {
	-webkit-transform: rotate(320deg);
	transform: rotate(320deg)
}

.kaleidoscope.n9 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(320deg);
	transform: scale(-1, 1) rotate(320deg)
}

.kaleidoscope.n9 .tile .image {
	-webkit-transform: rotate(20deg);
	transform: rotate(20deg)
}

.kaleidoscope.n10 .tile {
	display: block
}

.kaleidoscope.n10 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n10 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n10 .tile.t2 {
	-webkit-transform: rotate(36deg);
	transform: rotate(36deg)
}

.kaleidoscope.n10 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(36deg);
	transform: scale(-1, 1) rotate(36deg)
}

.kaleidoscope.n10 .tile.t4 {
	-webkit-transform: rotate(72deg);
	transform: rotate(72deg)
}

.kaleidoscope.n10 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(72deg);
	transform: scale(-1, 1) rotate(72deg)
}

.kaleidoscope.n10 .tile.t6 {
	-webkit-transform: rotate(108deg);
	transform: rotate(108deg)
}

.kaleidoscope.n10 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(108deg);
	transform: scale(-1, 1) rotate(108deg)
}

.kaleidoscope.n10 .tile.t8 {
	-webkit-transform: rotate(144deg);
	transform: rotate(144deg)
}

.kaleidoscope.n10 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(144deg);
	transform: scale(-1, 1) rotate(144deg)
}

.kaleidoscope.n10 .tile.t10 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n10 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n10 .tile.t12 {
	-webkit-transform: rotate(216deg);
	transform: rotate(216deg)
}

.kaleidoscope.n10 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(216deg);
	transform: scale(-1, 1) rotate(216deg)
}

.kaleidoscope.n10 .tile.t14 {
	-webkit-transform: rotate(252deg);
	transform: rotate(252deg)
}

.kaleidoscope.n10 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(252deg);
	transform: scale(-1, 1) rotate(252deg)
}

.kaleidoscope.n10 .tile.t16 {
	-webkit-transform: rotate(288deg);
	transform: rotate(288deg)
}

.kaleidoscope.n10 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(288deg);
	transform: scale(-1, 1) rotate(288deg)
}

.kaleidoscope.n10 .tile.t18 {
	-webkit-transform: rotate(324deg);
	transform: rotate(324deg)
}

.kaleidoscope.n10 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(324deg);
	transform: scale(-1, 1) rotate(324deg)
}

.kaleidoscope.n10 .tile .image {
	-webkit-transform: rotate(18deg);
	transform: rotate(18deg)
}

.kaleidoscope.n11 .tile {
	display: block
}

.kaleidoscope.n11 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n11 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n11 .tile.t2 {
	-webkit-transform: rotate(32.72727deg);
	transform: rotate(32.72727deg)
}

.kaleidoscope.n11 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(32.72727deg);
	transform: scale(-1, 1) rotate(32.72727deg)
}

.kaleidoscope.n11 .tile.t4 {
	-webkit-transform: rotate(65.45455deg);
	transform: rotate(65.45455deg)
}

.kaleidoscope.n11 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(65.45455deg);
	transform: scale(-1, 1) rotate(65.45455deg)
}

.kaleidoscope.n11 .tile.t6 {
	-webkit-transform: rotate(98.18182deg);
	transform: rotate(98.18182deg)
}

.kaleidoscope.n11 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(98.18182deg);
	transform: scale(-1, 1) rotate(98.18182deg)
}

.kaleidoscope.n11 .tile.t8 {
	-webkit-transform: rotate(130.90909deg);
	transform: rotate(130.90909deg)
}

.kaleidoscope.n11 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(130.90909deg);
	transform: scale(-1, 1) rotate(130.90909deg)
}

.kaleidoscope.n11 .tile.t10 {
	-webkit-transform: rotate(163.63636deg);
	transform: rotate(163.63636deg)
}

.kaleidoscope.n11 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(163.63636deg);
	transform: scale(-1, 1) rotate(163.63636deg)
}

.kaleidoscope.n11 .tile.t12 {
	-webkit-transform: rotate(196.36364deg);
	transform: rotate(196.36364deg)
}

.kaleidoscope.n11 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(196.36364deg);
	transform: scale(-1, 1) rotate(196.36364deg)
}

.kaleidoscope.n11 .tile.t14 {
	-webkit-transform: rotate(229.09091deg);
	transform: rotate(229.09091deg)
}

.kaleidoscope.n11 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(229.09091deg);
	transform: scale(-1, 1) rotate(229.09091deg)
}

.kaleidoscope.n11 .tile.t16 {
	-webkit-transform: rotate(261.81818deg);
	transform: rotate(261.81818deg)
}

.kaleidoscope.n11 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(261.81818deg);
	transform: scale(-1, 1) rotate(261.81818deg)
}

.kaleidoscope.n11 .tile.t18 {
	-webkit-transform: rotate(294.54545deg);
	transform: rotate(294.54545deg)
}

.kaleidoscope.n11 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(294.54545deg);
	transform: scale(-1, 1) rotate(294.54545deg)
}

.kaleidoscope.n11 .tile.t20 {
	-webkit-transform: rotate(327.27273deg);
	transform: rotate(327.27273deg)
}

.kaleidoscope.n11 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(327.27273deg);
	transform: scale(-1, 1) rotate(327.27273deg)
}

.kaleidoscope.n11 .tile .image {
	-webkit-transform: rotate(16.36364deg);
	transform: rotate(16.36364deg)
}

.kaleidoscope.n12 .tile {
	display: block
}

.kaleidoscope.n12 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n12 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n12 .tile.t2 {
	-webkit-transform: rotate(30deg);
	transform: rotate(30deg)
}

.kaleidoscope.n12 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(30deg);
	transform: scale(-1, 1) rotate(30deg)
}

.kaleidoscope.n12 .tile.t4 {
	-webkit-transform: rotate(60deg);
	transform: rotate(60deg)
}

.kaleidoscope.n12 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(60deg);
	transform: scale(-1, 1) rotate(60deg)
}

.kaleidoscope.n12 .tile.t6 {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.kaleidoscope.n12 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(90deg);
	transform: scale(-1, 1) rotate(90deg)
}

.kaleidoscope.n12 .tile.t8 {
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg)
}

.kaleidoscope.n12 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(120deg);
	transform: scale(-1, 1) rotate(120deg)
}

.kaleidoscope.n12 .tile.t10 {
	-webkit-transform: rotate(150deg);
	transform: rotate(150deg)
}

.kaleidoscope.n12 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(150deg);
	transform: scale(-1, 1) rotate(150deg)
}

.kaleidoscope.n12 .tile.t12 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n12 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n12 .tile.t14 {
	-webkit-transform: rotate(210deg);
	transform: rotate(210deg)
}

.kaleidoscope.n12 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(210deg);
	transform: scale(-1, 1) rotate(210deg)
}

.kaleidoscope.n12 .tile.t16 {
	-webkit-transform: rotate(240deg);
	transform: rotate(240deg)
}

.kaleidoscope.n12 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(240deg);
	transform: scale(-1, 1) rotate(240deg)
}

.kaleidoscope.n12 .tile.t18 {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg)
}

.kaleidoscope.n12 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(270deg);
	transform: scale(-1, 1) rotate(270deg)
}

.kaleidoscope.n12 .tile.t20 {
	-webkit-transform: rotate(300deg);
	transform: rotate(300deg)
}

.kaleidoscope.n12 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(300deg);
	transform: scale(-1, 1) rotate(300deg)
}

.kaleidoscope.n12 .tile.t22 {
	-webkit-transform: rotate(330deg);
	transform: rotate(330deg)
}

.kaleidoscope.n12 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(330deg);
	transform: scale(-1, 1) rotate(330deg)
}

.kaleidoscope.n12 .tile .image {
	-webkit-transform: rotate(15deg);
	transform: rotate(15deg)
}

.kaleidoscope.n13 .tile {
	display: block
}

.kaleidoscope.n13 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n13 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n13 .tile.t2 {
	-webkit-transform: rotate(27.69231deg);
	transform: rotate(27.69231deg)
}

.kaleidoscope.n13 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(27.69231deg);
	transform: scale(-1, 1) rotate(27.69231deg)
}

.kaleidoscope.n13 .tile.t4 {
	-webkit-transform: rotate(55.38462deg);
	transform: rotate(55.38462deg)
}

.kaleidoscope.n13 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(55.38462deg);
	transform: scale(-1, 1) rotate(55.38462deg)
}

.kaleidoscope.n13 .tile.t6 {
	-webkit-transform: rotate(83.07692deg);
	transform: rotate(83.07692deg)
}

.kaleidoscope.n13 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(83.07692deg);
	transform: scale(-1, 1) rotate(83.07692deg)
}

.kaleidoscope.n13 .tile.t8 {
	-webkit-transform: rotate(110.76923deg);
	transform: rotate(110.76923deg)
}

.kaleidoscope.n13 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(110.76923deg);
	transform: scale(-1, 1) rotate(110.76923deg)
}

.kaleidoscope.n13 .tile.t10 {
	-webkit-transform: rotate(138.46154deg);
	transform: rotate(138.46154deg)
}

.kaleidoscope.n13 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(138.46154deg);
	transform: scale(-1, 1) rotate(138.46154deg)
}

.kaleidoscope.n13 .tile.t12 {
	-webkit-transform: rotate(166.15385deg);
	transform: rotate(166.15385deg)
}

.kaleidoscope.n13 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(166.15385deg);
	transform: scale(-1, 1) rotate(166.15385deg)
}

.kaleidoscope.n13 .tile.t14 {
	-webkit-transform: rotate(193.84615deg);
	transform: rotate(193.84615deg)
}

.kaleidoscope.n13 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(193.84615deg);
	transform: scale(-1, 1) rotate(193.84615deg)
}

.kaleidoscope.n13 .tile.t16 {
	-webkit-transform: rotate(221.53846deg);
	transform: rotate(221.53846deg)
}

.kaleidoscope.n13 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(221.53846deg);
	transform: scale(-1, 1) rotate(221.53846deg)
}

.kaleidoscope.n13 .tile.t18 {
	-webkit-transform: rotate(249.23077deg);
	transform: rotate(249.23077deg)
}

.kaleidoscope.n13 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(249.23077deg);
	transform: scale(-1, 1) rotate(249.23077deg)
}

.kaleidoscope.n13 .tile.t20 {
	-webkit-transform: rotate(276.92308deg);
	transform: rotate(276.92308deg)
}

.kaleidoscope.n13 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(276.92308deg);
	transform: scale(-1, 1) rotate(276.92308deg)
}

.kaleidoscope.n13 .tile.t22 {
	-webkit-transform: rotate(304.61538deg);
	transform: rotate(304.61538deg)
}

.kaleidoscope.n13 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(304.61538deg);
	transform: scale(-1, 1) rotate(304.61538deg)
}

.kaleidoscope.n13 .tile.t24 {
	-webkit-transform: rotate(332.30769deg);
	transform: rotate(332.30769deg)
}

.kaleidoscope.n13 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(332.30769deg);
	transform: scale(-1, 1) rotate(332.30769deg)
}

.kaleidoscope.n13 .tile .image {
	-webkit-transform: rotate(13.84615deg);
	transform: rotate(13.84615deg)
}

.kaleidoscope.n14 .tile {
	display: block
}

.kaleidoscope.n14 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n14 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n14 .tile.t2 {
	-webkit-transform: rotate(25.71429deg);
	transform: rotate(25.71429deg)
}

.kaleidoscope.n14 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(25.71429deg);
	transform: scale(-1, 1) rotate(25.71429deg)
}

.kaleidoscope.n14 .tile.t4 {
	-webkit-transform: rotate(51.42857deg);
	transform: rotate(51.42857deg)
}

.kaleidoscope.n14 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(51.42857deg);
	transform: scale(-1, 1) rotate(51.42857deg)
}

.kaleidoscope.n14 .tile.t6 {
	-webkit-transform: rotate(77.14286deg);
	transform: rotate(77.14286deg)
}

.kaleidoscope.n14 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(77.14286deg);
	transform: scale(-1, 1) rotate(77.14286deg)
}

.kaleidoscope.n14 .tile.t8 {
	-webkit-transform: rotate(102.85714deg);
	transform: rotate(102.85714deg)
}

.kaleidoscope.n14 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(102.85714deg);
	transform: scale(-1, 1) rotate(102.85714deg)
}

.kaleidoscope.n14 .tile.t10 {
	-webkit-transform: rotate(128.57143deg);
	transform: rotate(128.57143deg)
}

.kaleidoscope.n14 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(128.57143deg);
	transform: scale(-1, 1) rotate(128.57143deg)
}

.kaleidoscope.n14 .tile.t12 {
	-webkit-transform: rotate(154.28571deg);
	transform: rotate(154.28571deg)
}

.kaleidoscope.n14 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(154.28571deg);
	transform: scale(-1, 1) rotate(154.28571deg)
}

.kaleidoscope.n14 .tile.t14 {
	-webkit-transform: rotate(180.0deg);
	transform: rotate(180.0deg)
}

.kaleidoscope.n14 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(180.0deg);
	transform: scale(-1, 1) rotate(180.0deg)
}

.kaleidoscope.n14 .tile.t16 {
	-webkit-transform: rotate(205.71429deg);
	transform: rotate(205.71429deg)
}

.kaleidoscope.n14 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(205.71429deg);
	transform: scale(-1, 1) rotate(205.71429deg)
}

.kaleidoscope.n14 .tile.t18 {
	-webkit-transform: rotate(231.42857deg);
	transform: rotate(231.42857deg)
}

.kaleidoscope.n14 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(231.42857deg);
	transform: scale(-1, 1) rotate(231.42857deg)
}

.kaleidoscope.n14 .tile.t20 {
	-webkit-transform: rotate(257.14286deg);
	transform: rotate(257.14286deg)
}

.kaleidoscope.n14 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(257.14286deg);
	transform: scale(-1, 1) rotate(257.14286deg)
}

.kaleidoscope.n14 .tile.t22 {
	-webkit-transform: rotate(282.85714deg);
	transform: rotate(282.85714deg)
}

.kaleidoscope.n14 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(282.85714deg);
	transform: scale(-1, 1) rotate(282.85714deg)
}

.kaleidoscope.n14 .tile.t24 {
	-webkit-transform: rotate(308.57143deg);
	transform: rotate(308.57143deg)
}

.kaleidoscope.n14 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(308.57143deg);
	transform: scale(-1, 1) rotate(308.57143deg)
}

.kaleidoscope.n14 .tile.t26 {
	-webkit-transform: rotate(334.28571deg);
	transform: rotate(334.28571deg)
}

.kaleidoscope.n14 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(334.28571deg);
	transform: scale(-1, 1) rotate(334.28571deg)
}

.kaleidoscope.n14 .tile .image {
	-webkit-transform: rotate(12.85714deg);
	transform: rotate(12.85714deg)
}

.kaleidoscope.n15 .tile {
	display: block
}

.kaleidoscope.n15 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n15 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n15 .tile.t2 {
	-webkit-transform: rotate(24deg);
	transform: rotate(24deg)
}

.kaleidoscope.n15 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(24deg);
	transform: scale(-1, 1) rotate(24deg)
}

.kaleidoscope.n15 .tile.t4 {
	-webkit-transform: rotate(48deg);
	transform: rotate(48deg)
}

.kaleidoscope.n15 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(48deg);
	transform: scale(-1, 1) rotate(48deg)
}

.kaleidoscope.n15 .tile.t6 {
	-webkit-transform: rotate(72deg);
	transform: rotate(72deg)
}

.kaleidoscope.n15 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(72deg);
	transform: scale(-1, 1) rotate(72deg)
}

.kaleidoscope.n15 .tile.t8 {
	-webkit-transform: rotate(96deg);
	transform: rotate(96deg)
}

.kaleidoscope.n15 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(96deg);
	transform: scale(-1, 1) rotate(96deg)
}

.kaleidoscope.n15 .tile.t10 {
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg)
}

.kaleidoscope.n15 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(120deg);
	transform: scale(-1, 1) rotate(120deg)
}

.kaleidoscope.n15 .tile.t12 {
	-webkit-transform: rotate(144deg);
	transform: rotate(144deg)
}

.kaleidoscope.n15 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(144deg);
	transform: scale(-1, 1) rotate(144deg)
}

.kaleidoscope.n15 .tile.t14 {
	-webkit-transform: rotate(168deg);
	transform: rotate(168deg)
}

.kaleidoscope.n15 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(168deg);
	transform: scale(-1, 1) rotate(168deg)
}

.kaleidoscope.n15 .tile.t16 {
	-webkit-transform: rotate(192deg);
	transform: rotate(192deg)
}

.kaleidoscope.n15 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(192deg);
	transform: scale(-1, 1) rotate(192deg)
}

.kaleidoscope.n15 .tile.t18 {
	-webkit-transform: rotate(216deg);
	transform: rotate(216deg)
}

.kaleidoscope.n15 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(216deg);
	transform: scale(-1, 1) rotate(216deg)
}

.kaleidoscope.n15 .tile.t20 {
	-webkit-transform: rotate(240deg);
	transform: rotate(240deg)
}

.kaleidoscope.n15 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(240deg);
	transform: scale(-1, 1) rotate(240deg)
}

.kaleidoscope.n15 .tile.t22 {
	-webkit-transform: rotate(264deg);
	transform: rotate(264deg)
}

.kaleidoscope.n15 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(264deg);
	transform: scale(-1, 1) rotate(264deg)
}

.kaleidoscope.n15 .tile.t24 {
	-webkit-transform: rotate(288deg);
	transform: rotate(288deg)
}

.kaleidoscope.n15 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(288deg);
	transform: scale(-1, 1) rotate(288deg)
}

.kaleidoscope.n15 .tile.t26 {
	-webkit-transform: rotate(312deg);
	transform: rotate(312deg)
}

.kaleidoscope.n15 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(312deg);
	transform: scale(-1, 1) rotate(312deg)
}

.kaleidoscope.n15 .tile.t28 {
	-webkit-transform: rotate(336deg);
	transform: rotate(336deg)
}

.kaleidoscope.n15 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(336deg);
	transform: scale(-1, 1) rotate(336deg)
}

.kaleidoscope.n15 .tile .image {
	-webkit-transform: rotate(12deg);
	transform: rotate(12deg)
}

.kaleidoscope.n16 .tile {
	display: block
}

.kaleidoscope.n16 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n16 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n16 .tile.t2 {
	-webkit-transform: rotate(22.5deg);
	transform: rotate(22.5deg)
}

.kaleidoscope.n16 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(22.5deg);
	transform: scale(-1, 1) rotate(22.5deg)
}

.kaleidoscope.n16 .tile.t4 {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg)
}

.kaleidoscope.n16 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(45deg);
	transform: scale(-1, 1) rotate(45deg)
}

.kaleidoscope.n16 .tile.t6 {
	-webkit-transform: rotate(67.5deg);
	transform: rotate(67.5deg)
}

.kaleidoscope.n16 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(67.5deg);
	transform: scale(-1, 1) rotate(67.5deg)
}

.kaleidoscope.n16 .tile.t8 {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.kaleidoscope.n16 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(90deg);
	transform: scale(-1, 1) rotate(90deg)
}

.kaleidoscope.n16 .tile.t10 {
	-webkit-transform: rotate(112.5deg);
	transform: rotate(112.5deg)
}

.kaleidoscope.n16 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(112.5deg);
	transform: scale(-1, 1) rotate(112.5deg)
}

.kaleidoscope.n16 .tile.t12 {
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg)
}

.kaleidoscope.n16 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(135deg);
	transform: scale(-1, 1) rotate(135deg)
}

.kaleidoscope.n16 .tile.t14 {
	-webkit-transform: rotate(157.5deg);
	transform: rotate(157.5deg)
}

.kaleidoscope.n16 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(157.5deg);
	transform: scale(-1, 1) rotate(157.5deg)
}

.kaleidoscope.n16 .tile.t16 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n16 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n16 .tile.t18 {
	-webkit-transform: rotate(202.5deg);
	transform: rotate(202.5deg)
}

.kaleidoscope.n16 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(202.5deg);
	transform: scale(-1, 1) rotate(202.5deg)
}

.kaleidoscope.n16 .tile.t20 {
	-webkit-transform: rotate(225deg);
	transform: rotate(225deg)
}

.kaleidoscope.n16 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(225deg);
	transform: scale(-1, 1) rotate(225deg)
}

.kaleidoscope.n16 .tile.t22 {
	-webkit-transform: rotate(247.5deg);
	transform: rotate(247.5deg)
}

.kaleidoscope.n16 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(247.5deg);
	transform: scale(-1, 1) rotate(247.5deg)
}

.kaleidoscope.n16 .tile.t24 {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg)
}

.kaleidoscope.n16 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(270deg);
	transform: scale(-1, 1) rotate(270deg)
}

.kaleidoscope.n16 .tile.t26 {
	-webkit-transform: rotate(292.5deg);
	transform: rotate(292.5deg)
}

.kaleidoscope.n16 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(292.5deg);
	transform: scale(-1, 1) rotate(292.5deg)
}

.kaleidoscope.n16 .tile.t28 {
	-webkit-transform: rotate(315deg);
	transform: rotate(315deg)
}

.kaleidoscope.n16 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(315deg);
	transform: scale(-1, 1) rotate(315deg)
}

.kaleidoscope.n16 .tile.t30 {
	-webkit-transform: rotate(337.5deg);
	transform: rotate(337.5deg)
}

.kaleidoscope.n16 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(337.5deg);
	transform: scale(-1, 1) rotate(337.5deg)
}

.kaleidoscope.n16 .tile .image {
	-webkit-transform: rotate(11.25deg);
	transform: rotate(11.25deg)
}

.kaleidoscope.n17 .tile {
	display: block
}

.kaleidoscope.n17 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n17 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n17 .tile.t2 {
	-webkit-transform: rotate(21.17647deg);
	transform: rotate(21.17647deg)
}

.kaleidoscope.n17 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(21.17647deg);
	transform: scale(-1, 1) rotate(21.17647deg)
}

.kaleidoscope.n17 .tile.t4 {
	-webkit-transform: rotate(42.35294deg);
	transform: rotate(42.35294deg)
}

.kaleidoscope.n17 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(42.35294deg);
	transform: scale(-1, 1) rotate(42.35294deg)
}

.kaleidoscope.n17 .tile.t6 {
	-webkit-transform: rotate(63.52941deg);
	transform: rotate(63.52941deg)
}

.kaleidoscope.n17 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(63.52941deg);
	transform: scale(-1, 1) rotate(63.52941deg)
}

.kaleidoscope.n17 .tile.t8 {
	-webkit-transform: rotate(84.70588deg);
	transform: rotate(84.70588deg)
}

.kaleidoscope.n17 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(84.70588deg);
	transform: scale(-1, 1) rotate(84.70588deg)
}

.kaleidoscope.n17 .tile.t10 {
	-webkit-transform: rotate(105.88235deg);
	transform: rotate(105.88235deg)
}

.kaleidoscope.n17 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(105.88235deg);
	transform: scale(-1, 1) rotate(105.88235deg)
}

.kaleidoscope.n17 .tile.t12 {
	-webkit-transform: rotate(127.05882deg);
	transform: rotate(127.05882deg)
}

.kaleidoscope.n17 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(127.05882deg);
	transform: scale(-1, 1) rotate(127.05882deg)
}

.kaleidoscope.n17 .tile.t14 {
	-webkit-transform: rotate(148.23529deg);
	transform: rotate(148.23529deg)
}

.kaleidoscope.n17 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(148.23529deg);
	transform: scale(-1, 1) rotate(148.23529deg)
}

.kaleidoscope.n17 .tile.t16 {
	-webkit-transform: rotate(169.41176deg);
	transform: rotate(169.41176deg)
}

.kaleidoscope.n17 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(169.41176deg);
	transform: scale(-1, 1) rotate(169.41176deg)
}

.kaleidoscope.n17 .tile.t18 {
	-webkit-transform: rotate(190.58824deg);
	transform: rotate(190.58824deg)
}

.kaleidoscope.n17 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(190.58824deg);
	transform: scale(-1, 1) rotate(190.58824deg)
}

.kaleidoscope.n17 .tile.t20 {
	-webkit-transform: rotate(211.76471deg);
	transform: rotate(211.76471deg)
}

.kaleidoscope.n17 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(211.76471deg);
	transform: scale(-1, 1) rotate(211.76471deg)
}

.kaleidoscope.n17 .tile.t22 {
	-webkit-transform: rotate(232.94118deg);
	transform: rotate(232.94118deg)
}

.kaleidoscope.n17 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(232.94118deg);
	transform: scale(-1, 1) rotate(232.94118deg)
}

.kaleidoscope.n17 .tile.t24 {
	-webkit-transform: rotate(254.11765deg);
	transform: rotate(254.11765deg)
}

.kaleidoscope.n17 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(254.11765deg);
	transform: scale(-1, 1) rotate(254.11765deg)
}

.kaleidoscope.n17 .tile.t26 {
	-webkit-transform: rotate(275.29412deg);
	transform: rotate(275.29412deg)
}

.kaleidoscope.n17 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(275.29412deg);
	transform: scale(-1, 1) rotate(275.29412deg)
}

.kaleidoscope.n17 .tile.t28 {
	-webkit-transform: rotate(296.47059deg);
	transform: rotate(296.47059deg)
}

.kaleidoscope.n17 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(296.47059deg);
	transform: scale(-1, 1) rotate(296.47059deg)
}

.kaleidoscope.n17 .tile.t30 {
	-webkit-transform: rotate(317.64706deg);
	transform: rotate(317.64706deg)
}

.kaleidoscope.n17 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(317.64706deg);
	transform: scale(-1, 1) rotate(317.64706deg)
}

.kaleidoscope.n17 .tile.t32 {
	-webkit-transform: rotate(338.82353deg);
	transform: rotate(338.82353deg)
}

.kaleidoscope.n17 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(338.82353deg);
	transform: scale(-1, 1) rotate(338.82353deg)
}

.kaleidoscope.n17 .tile .image {
	-webkit-transform: rotate(10.58824deg);
	transform: rotate(10.58824deg)
}

.kaleidoscope.n18 .tile {
	display: block
}

.kaleidoscope.n18 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n18 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n18 .tile.t2 {
	-webkit-transform: rotate(20deg);
	transform: rotate(20deg)
}

.kaleidoscope.n18 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(20deg);
	transform: scale(-1, 1) rotate(20deg)
}

.kaleidoscope.n18 .tile.t4 {
	-webkit-transform: rotate(40deg);
	transform: rotate(40deg)
}

.kaleidoscope.n18 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(40deg);
	transform: scale(-1, 1) rotate(40deg)
}

.kaleidoscope.n18 .tile.t6 {
	-webkit-transform: rotate(60deg);
	transform: rotate(60deg)
}

.kaleidoscope.n18 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(60deg);
	transform: scale(-1, 1) rotate(60deg)
}

.kaleidoscope.n18 .tile.t8 {
	-webkit-transform: rotate(80deg);
	transform: rotate(80deg)
}

.kaleidoscope.n18 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(80deg);
	transform: scale(-1, 1) rotate(80deg)
}

.kaleidoscope.n18 .tile.t10 {
	-webkit-transform: rotate(100deg);
	transform: rotate(100deg)
}

.kaleidoscope.n18 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(100deg);
	transform: scale(-1, 1) rotate(100deg)
}

.kaleidoscope.n18 .tile.t12 {
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg)
}

.kaleidoscope.n18 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(120deg);
	transform: scale(-1, 1) rotate(120deg)
}

.kaleidoscope.n18 .tile.t14 {
	-webkit-transform: rotate(140deg);
	transform: rotate(140deg)
}

.kaleidoscope.n18 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(140deg);
	transform: scale(-1, 1) rotate(140deg)
}

.kaleidoscope.n18 .tile.t16 {
	-webkit-transform: rotate(160deg);
	transform: rotate(160deg)
}

.kaleidoscope.n18 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(160deg);
	transform: scale(-1, 1) rotate(160deg)
}

.kaleidoscope.n18 .tile.t18 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n18 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n18 .tile.t20 {
	-webkit-transform: rotate(200deg);
	transform: rotate(200deg)
}

.kaleidoscope.n18 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(200deg);
	transform: scale(-1, 1) rotate(200deg)
}

.kaleidoscope.n18 .tile.t22 {
	-webkit-transform: rotate(220deg);
	transform: rotate(220deg)
}

.kaleidoscope.n18 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(220deg);
	transform: scale(-1, 1) rotate(220deg)
}

.kaleidoscope.n18 .tile.t24 {
	-webkit-transform: rotate(240deg);
	transform: rotate(240deg)
}

.kaleidoscope.n18 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(240deg);
	transform: scale(-1, 1) rotate(240deg)
}

.kaleidoscope.n18 .tile.t26 {
	-webkit-transform: rotate(260deg);
	transform: rotate(260deg)
}

.kaleidoscope.n18 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(260deg);
	transform: scale(-1, 1) rotate(260deg)
}

.kaleidoscope.n18 .tile.t28 {
	-webkit-transform: rotate(280deg);
	transform: rotate(280deg)
}

.kaleidoscope.n18 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(280deg);
	transform: scale(-1, 1) rotate(280deg)
}

.kaleidoscope.n18 .tile.t30 {
	-webkit-transform: rotate(300deg);
	transform: rotate(300deg)
}

.kaleidoscope.n18 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(300deg);
	transform: scale(-1, 1) rotate(300deg)
}

.kaleidoscope.n18 .tile.t32 {
	-webkit-transform: rotate(320deg);
	transform: rotate(320deg)
}

.kaleidoscope.n18 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(320deg);
	transform: scale(-1, 1) rotate(320deg)
}

.kaleidoscope.n18 .tile.t34 {
	-webkit-transform: rotate(340deg);
	transform: rotate(340deg)
}

.kaleidoscope.n18 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(340deg);
	transform: scale(-1, 1) rotate(340deg)
}

.kaleidoscope.n18 .tile .image {
	-webkit-transform: rotate(10deg);
	transform: rotate(10deg)
}

.kaleidoscope.n19 .tile {
	display: block
}

.kaleidoscope.n19 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n19 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n19 .tile.t2 {
	-webkit-transform: rotate(18.94737deg);
	transform: rotate(18.94737deg)
}

.kaleidoscope.n19 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(18.94737deg);
	transform: scale(-1, 1) rotate(18.94737deg)
}

.kaleidoscope.n19 .tile.t4 {
	-webkit-transform: rotate(37.89474deg);
	transform: rotate(37.89474deg)
}

.kaleidoscope.n19 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(37.89474deg);
	transform: scale(-1, 1) rotate(37.89474deg)
}

.kaleidoscope.n19 .tile.t6 {
	-webkit-transform: rotate(56.84211deg);
	transform: rotate(56.84211deg)
}

.kaleidoscope.n19 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(56.84211deg);
	transform: scale(-1, 1) rotate(56.84211deg)
}

.kaleidoscope.n19 .tile.t8 {
	-webkit-transform: rotate(75.78947deg);
	transform: rotate(75.78947deg)
}

.kaleidoscope.n19 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(75.78947deg);
	transform: scale(-1, 1) rotate(75.78947deg)
}

.kaleidoscope.n19 .tile.t10 {
	-webkit-transform: rotate(94.73684deg);
	transform: rotate(94.73684deg)
}

.kaleidoscope.n19 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(94.73684deg);
	transform: scale(-1, 1) rotate(94.73684deg)
}

.kaleidoscope.n19 .tile.t12 {
	-webkit-transform: rotate(113.68421deg);
	transform: rotate(113.68421deg)
}

.kaleidoscope.n19 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(113.68421deg);
	transform: scale(-1, 1) rotate(113.68421deg)
}

.kaleidoscope.n19 .tile.t14 {
	-webkit-transform: rotate(132.63158deg);
	transform: rotate(132.63158deg)
}

.kaleidoscope.n19 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(132.63158deg);
	transform: scale(-1, 1) rotate(132.63158deg)
}

.kaleidoscope.n19 .tile.t16 {
	-webkit-transform: rotate(151.57895deg);
	transform: rotate(151.57895deg)
}

.kaleidoscope.n19 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(151.57895deg);
	transform: scale(-1, 1) rotate(151.57895deg)
}

.kaleidoscope.n19 .tile.t18 {
	-webkit-transform: rotate(170.52632deg);
	transform: rotate(170.52632deg)
}

.kaleidoscope.n19 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(170.52632deg);
	transform: scale(-1, 1) rotate(170.52632deg)
}

.kaleidoscope.n19 .tile.t20 {
	-webkit-transform: rotate(189.47368deg);
	transform: rotate(189.47368deg)
}

.kaleidoscope.n19 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(189.47368deg);
	transform: scale(-1, 1) rotate(189.47368deg)
}

.kaleidoscope.n19 .tile.t22 {
	-webkit-transform: rotate(208.42105deg);
	transform: rotate(208.42105deg)
}

.kaleidoscope.n19 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(208.42105deg);
	transform: scale(-1, 1) rotate(208.42105deg)
}

.kaleidoscope.n19 .tile.t24 {
	-webkit-transform: rotate(227.36842deg);
	transform: rotate(227.36842deg)
}

.kaleidoscope.n19 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(227.36842deg);
	transform: scale(-1, 1) rotate(227.36842deg)
}

.kaleidoscope.n19 .tile.t26 {
	-webkit-transform: rotate(246.31579deg);
	transform: rotate(246.31579deg)
}

.kaleidoscope.n19 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(246.31579deg);
	transform: scale(-1, 1) rotate(246.31579deg)
}

.kaleidoscope.n19 .tile.t28 {
	-webkit-transform: rotate(265.26316deg);
	transform: rotate(265.26316deg)
}

.kaleidoscope.n19 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(265.26316deg);
	transform: scale(-1, 1) rotate(265.26316deg)
}

.kaleidoscope.n19 .tile.t30 {
	-webkit-transform: rotate(284.21053deg);
	transform: rotate(284.21053deg)
}

.kaleidoscope.n19 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(284.21053deg);
	transform: scale(-1, 1) rotate(284.21053deg)
}

.kaleidoscope.n19 .tile.t32 {
	-webkit-transform: rotate(303.15789deg);
	transform: rotate(303.15789deg)
}

.kaleidoscope.n19 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(303.15789deg);
	transform: scale(-1, 1) rotate(303.15789deg)
}

.kaleidoscope.n19 .tile.t34 {
	-webkit-transform: rotate(322.10526deg);
	transform: rotate(322.10526deg)
}

.kaleidoscope.n19 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(322.10526deg);
	transform: scale(-1, 1) rotate(322.10526deg)
}

.kaleidoscope.n19 .tile.t36 {
	-webkit-transform: rotate(341.05263deg);
	transform: rotate(341.05263deg)
}

.kaleidoscope.n19 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(341.05263deg);
	transform: scale(-1, 1) rotate(341.05263deg)
}

.kaleidoscope.n19 .tile .image {
	-webkit-transform: rotate(9.47368deg);
	transform: rotate(9.47368deg)
}

.kaleidoscope.n20 .tile {
	display: block
}

.kaleidoscope.n20 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n20 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n20 .tile.t2 {
	-webkit-transform: rotate(18deg);
	transform: rotate(18deg)
}

.kaleidoscope.n20 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(18deg);
	transform: scale(-1, 1) rotate(18deg)
}

.kaleidoscope.n20 .tile.t4 {
	-webkit-transform: rotate(36deg);
	transform: rotate(36deg)
}

.kaleidoscope.n20 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(36deg);
	transform: scale(-1, 1) rotate(36deg)
}

.kaleidoscope.n20 .tile.t6 {
	-webkit-transform: rotate(54deg);
	transform: rotate(54deg)
}

.kaleidoscope.n20 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(54deg);
	transform: scale(-1, 1) rotate(54deg)
}

.kaleidoscope.n20 .tile.t8 {
	-webkit-transform: rotate(72deg);
	transform: rotate(72deg)
}

.kaleidoscope.n20 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(72deg);
	transform: scale(-1, 1) rotate(72deg)
}

.kaleidoscope.n20 .tile.t10 {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.kaleidoscope.n20 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(90deg);
	transform: scale(-1, 1) rotate(90deg)
}

.kaleidoscope.n20 .tile.t12 {
	-webkit-transform: rotate(108deg);
	transform: rotate(108deg)
}

.kaleidoscope.n20 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(108deg);
	transform: scale(-1, 1) rotate(108deg)
}

.kaleidoscope.n20 .tile.t14 {
	-webkit-transform: rotate(126deg);
	transform: rotate(126deg)
}

.kaleidoscope.n20 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(126deg);
	transform: scale(-1, 1) rotate(126deg)
}

.kaleidoscope.n20 .tile.t16 {
	-webkit-transform: rotate(144deg);
	transform: rotate(144deg)
}

.kaleidoscope.n20 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(144deg);
	transform: scale(-1, 1) rotate(144deg)
}

.kaleidoscope.n20 .tile.t18 {
	-webkit-transform: rotate(162deg);
	transform: rotate(162deg)
}

.kaleidoscope.n20 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(162deg);
	transform: scale(-1, 1) rotate(162deg)
}

.kaleidoscope.n20 .tile.t20 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n20 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n20 .tile.t22 {
	-webkit-transform: rotate(198deg);
	transform: rotate(198deg)
}

.kaleidoscope.n20 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(198deg);
	transform: scale(-1, 1) rotate(198deg)
}

.kaleidoscope.n20 .tile.t24 {
	-webkit-transform: rotate(216deg);
	transform: rotate(216deg)
}

.kaleidoscope.n20 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(216deg);
	transform: scale(-1, 1) rotate(216deg)
}

.kaleidoscope.n20 .tile.t26 {
	-webkit-transform: rotate(234deg);
	transform: rotate(234deg)
}

.kaleidoscope.n20 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(234deg);
	transform: scale(-1, 1) rotate(234deg)
}

.kaleidoscope.n20 .tile.t28 {
	-webkit-transform: rotate(252deg);
	transform: rotate(252deg)
}

.kaleidoscope.n20 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(252deg);
	transform: scale(-1, 1) rotate(252deg)
}

.kaleidoscope.n20 .tile.t30 {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg)
}

.kaleidoscope.n20 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(270deg);
	transform: scale(-1, 1) rotate(270deg)
}

.kaleidoscope.n20 .tile.t32 {
	-webkit-transform: rotate(288deg);
	transform: rotate(288deg)
}

.kaleidoscope.n20 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(288deg);
	transform: scale(-1, 1) rotate(288deg)
}

.kaleidoscope.n20 .tile.t34 {
	-webkit-transform: rotate(306deg);
	transform: rotate(306deg)
}

.kaleidoscope.n20 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(306deg);
	transform: scale(-1, 1) rotate(306deg)
}

.kaleidoscope.n20 .tile.t36 {
	-webkit-transform: rotate(324deg);
	transform: rotate(324deg)
}

.kaleidoscope.n20 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(324deg);
	transform: scale(-1, 1) rotate(324deg)
}

.kaleidoscope.n20 .tile.t38 {
	-webkit-transform: rotate(342deg);
	transform: rotate(342deg)
}

.kaleidoscope.n20 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(342deg);
	transform: scale(-1, 1) rotate(342deg)
}

.kaleidoscope.n20 .tile .image {
	-webkit-transform: rotate(9deg);
	transform: rotate(9deg)
}

.kaleidoscope.n21 .tile {
	display: block
}

.kaleidoscope.n21 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n21 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n21 .tile.t2 {
	-webkit-transform: rotate(17.14286deg);
	transform: rotate(17.14286deg)
}

.kaleidoscope.n21 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(17.14286deg);
	transform: scale(-1, 1) rotate(17.14286deg)
}

.kaleidoscope.n21 .tile.t4 {
	-webkit-transform: rotate(34.28571deg);
	transform: rotate(34.28571deg)
}

.kaleidoscope.n21 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(34.28571deg);
	transform: scale(-1, 1) rotate(34.28571deg)
}

.kaleidoscope.n21 .tile.t6 {
	-webkit-transform: rotate(51.42857deg);
	transform: rotate(51.42857deg)
}

.kaleidoscope.n21 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(51.42857deg);
	transform: scale(-1, 1) rotate(51.42857deg)
}

.kaleidoscope.n21 .tile.t8 {
	-webkit-transform: rotate(68.57143deg);
	transform: rotate(68.57143deg)
}

.kaleidoscope.n21 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(68.57143deg);
	transform: scale(-1, 1) rotate(68.57143deg)
}

.kaleidoscope.n21 .tile.t10 {
	-webkit-transform: rotate(85.71429deg);
	transform: rotate(85.71429deg)
}

.kaleidoscope.n21 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(85.71429deg);
	transform: scale(-1, 1) rotate(85.71429deg)
}

.kaleidoscope.n21 .tile.t12 {
	-webkit-transform: rotate(102.85714deg);
	transform: rotate(102.85714deg)
}

.kaleidoscope.n21 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(102.85714deg);
	transform: scale(-1, 1) rotate(102.85714deg)
}

.kaleidoscope.n21 .tile.t14 {
	-webkit-transform: rotate(120.0deg);
	transform: rotate(120.0deg)
}

.kaleidoscope.n21 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(120.0deg);
	transform: scale(-1, 1) rotate(120.0deg)
}

.kaleidoscope.n21 .tile.t16 {
	-webkit-transform: rotate(137.14286deg);
	transform: rotate(137.14286deg)
}

.kaleidoscope.n21 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(137.14286deg);
	transform: scale(-1, 1) rotate(137.14286deg)
}

.kaleidoscope.n21 .tile.t18 {
	-webkit-transform: rotate(154.28571deg);
	transform: rotate(154.28571deg)
}

.kaleidoscope.n21 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(154.28571deg);
	transform: scale(-1, 1) rotate(154.28571deg)
}

.kaleidoscope.n21 .tile.t20 {
	-webkit-transform: rotate(171.42857deg);
	transform: rotate(171.42857deg)
}

.kaleidoscope.n21 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(171.42857deg);
	transform: scale(-1, 1) rotate(171.42857deg)
}

.kaleidoscope.n21 .tile.t22 {
	-webkit-transform: rotate(188.57143deg);
	transform: rotate(188.57143deg)
}

.kaleidoscope.n21 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(188.57143deg);
	transform: scale(-1, 1) rotate(188.57143deg)
}

.kaleidoscope.n21 .tile.t24 {
	-webkit-transform: rotate(205.71429deg);
	transform: rotate(205.71429deg)
}

.kaleidoscope.n21 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(205.71429deg);
	transform: scale(-1, 1) rotate(205.71429deg)
}

.kaleidoscope.n21 .tile.t26 {
	-webkit-transform: rotate(222.85714deg);
	transform: rotate(222.85714deg)
}

.kaleidoscope.n21 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(222.85714deg);
	transform: scale(-1, 1) rotate(222.85714deg)
}

.kaleidoscope.n21 .tile.t28 {
	-webkit-transform: rotate(240.0deg);
	transform: rotate(240.0deg)
}

.kaleidoscope.n21 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(240.0deg);
	transform: scale(-1, 1) rotate(240.0deg)
}

.kaleidoscope.n21 .tile.t30 {
	-webkit-transform: rotate(257.14286deg);
	transform: rotate(257.14286deg)
}

.kaleidoscope.n21 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(257.14286deg);
	transform: scale(-1, 1) rotate(257.14286deg)
}

.kaleidoscope.n21 .tile.t32 {
	-webkit-transform: rotate(274.28571deg);
	transform: rotate(274.28571deg)
}

.kaleidoscope.n21 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(274.28571deg);
	transform: scale(-1, 1) rotate(274.28571deg)
}

.kaleidoscope.n21 .tile.t34 {
	-webkit-transform: rotate(291.42857deg);
	transform: rotate(291.42857deg)
}

.kaleidoscope.n21 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(291.42857deg);
	transform: scale(-1, 1) rotate(291.42857deg)
}

.kaleidoscope.n21 .tile.t36 {
	-webkit-transform: rotate(308.57143deg);
	transform: rotate(308.57143deg)
}

.kaleidoscope.n21 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(308.57143deg);
	transform: scale(-1, 1) rotate(308.57143deg)
}

.kaleidoscope.n21 .tile.t38 {
	-webkit-transform: rotate(325.71429deg);
	transform: rotate(325.71429deg)
}

.kaleidoscope.n21 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(325.71429deg);
	transform: scale(-1, 1) rotate(325.71429deg)
}

.kaleidoscope.n21 .tile.t40 {
	-webkit-transform: rotate(342.85714deg);
	transform: rotate(342.85714deg)
}

.kaleidoscope.n21 .tile.t41 {
	-webkit-transform: scale(-1, 1) rotate(342.85714deg);
	transform: scale(-1, 1) rotate(342.85714deg)
}

.kaleidoscope.n21 .tile .image {
	-webkit-transform: rotate(8.57143deg);
	transform: rotate(8.57143deg)
}

.kaleidoscope.n22 .tile {
	display: block
}

.kaleidoscope.n22 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n22 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n22 .tile.t2 {
	-webkit-transform: rotate(16.36364deg);
	transform: rotate(16.36364deg)
}

.kaleidoscope.n22 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(16.36364deg);
	transform: scale(-1, 1) rotate(16.36364deg)
}

.kaleidoscope.n22 .tile.t4 {
	-webkit-transform: rotate(32.72727deg);
	transform: rotate(32.72727deg)
}

.kaleidoscope.n22 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(32.72727deg);
	transform: scale(-1, 1) rotate(32.72727deg)
}

.kaleidoscope.n22 .tile.t6 {
	-webkit-transform: rotate(49.09091deg);
	transform: rotate(49.09091deg)
}

.kaleidoscope.n22 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(49.09091deg);
	transform: scale(-1, 1) rotate(49.09091deg)
}

.kaleidoscope.n22 .tile.t8 {
	-webkit-transform: rotate(65.45455deg);
	transform: rotate(65.45455deg)
}

.kaleidoscope.n22 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(65.45455deg);
	transform: scale(-1, 1) rotate(65.45455deg)
}

.kaleidoscope.n22 .tile.t10 {
	-webkit-transform: rotate(81.81818deg);
	transform: rotate(81.81818deg)
}

.kaleidoscope.n22 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(81.81818deg);
	transform: scale(-1, 1) rotate(81.81818deg)
}

.kaleidoscope.n22 .tile.t12 {
	-webkit-transform: rotate(98.18182deg);
	transform: rotate(98.18182deg)
}

.kaleidoscope.n22 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(98.18182deg);
	transform: scale(-1, 1) rotate(98.18182deg)
}

.kaleidoscope.n22 .tile.t14 {
	-webkit-transform: rotate(114.54545deg);
	transform: rotate(114.54545deg)
}

.kaleidoscope.n22 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(114.54545deg);
	transform: scale(-1, 1) rotate(114.54545deg)
}

.kaleidoscope.n22 .tile.t16 {
	-webkit-transform: rotate(130.90909deg);
	transform: rotate(130.90909deg)
}

.kaleidoscope.n22 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(130.90909deg);
	transform: scale(-1, 1) rotate(130.90909deg)
}

.kaleidoscope.n22 .tile.t18 {
	-webkit-transform: rotate(147.27273deg);
	transform: rotate(147.27273deg)
}

.kaleidoscope.n22 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(147.27273deg);
	transform: scale(-1, 1) rotate(147.27273deg)
}

.kaleidoscope.n22 .tile.t20 {
	-webkit-transform: rotate(163.63636deg);
	transform: rotate(163.63636deg)
}

.kaleidoscope.n22 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(163.63636deg);
	transform: scale(-1, 1) rotate(163.63636deg)
}

.kaleidoscope.n22 .tile.t22 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n22 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n22 .tile.t24 {
	-webkit-transform: rotate(196.36364deg);
	transform: rotate(196.36364deg)
}

.kaleidoscope.n22 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(196.36364deg);
	transform: scale(-1, 1) rotate(196.36364deg)
}

.kaleidoscope.n22 .tile.t26 {
	-webkit-transform: rotate(212.72727deg);
	transform: rotate(212.72727deg)
}

.kaleidoscope.n22 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(212.72727deg);
	transform: scale(-1, 1) rotate(212.72727deg)
}

.kaleidoscope.n22 .tile.t28 {
	-webkit-transform: rotate(229.09091deg);
	transform: rotate(229.09091deg)
}

.kaleidoscope.n22 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(229.09091deg);
	transform: scale(-1, 1) rotate(229.09091deg)
}

.kaleidoscope.n22 .tile.t30 {
	-webkit-transform: rotate(245.45455deg);
	transform: rotate(245.45455deg)
}

.kaleidoscope.n22 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(245.45455deg);
	transform: scale(-1, 1) rotate(245.45455deg)
}

.kaleidoscope.n22 .tile.t32 {
	-webkit-transform: rotate(261.81818deg);
	transform: rotate(261.81818deg)
}

.kaleidoscope.n22 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(261.81818deg);
	transform: scale(-1, 1) rotate(261.81818deg)
}

.kaleidoscope.n22 .tile.t34 {
	-webkit-transform: rotate(278.18182deg);
	transform: rotate(278.18182deg)
}

.kaleidoscope.n22 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(278.18182deg);
	transform: scale(-1, 1) rotate(278.18182deg)
}

.kaleidoscope.n22 .tile.t36 {
	-webkit-transform: rotate(294.54545deg);
	transform: rotate(294.54545deg)
}

.kaleidoscope.n22 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(294.54545deg);
	transform: scale(-1, 1) rotate(294.54545deg)
}

.kaleidoscope.n22 .tile.t38 {
	-webkit-transform: rotate(310.90909deg);
	transform: rotate(310.90909deg)
}

.kaleidoscope.n22 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(310.90909deg);
	transform: scale(-1, 1) rotate(310.90909deg)
}

.kaleidoscope.n22 .tile.t40 {
	-webkit-transform: rotate(327.27273deg);
	transform: rotate(327.27273deg)
}

.kaleidoscope.n22 .tile.t41 {
	-webkit-transform: scale(-1, 1) rotate(327.27273deg);
	transform: scale(-1, 1) rotate(327.27273deg)
}

.kaleidoscope.n22 .tile.t42 {
	-webkit-transform: rotate(343.63636deg);
	transform: rotate(343.63636deg)
}

.kaleidoscope.n22 .tile.t43 {
	-webkit-transform: scale(-1, 1) rotate(343.63636deg);
	transform: scale(-1, 1) rotate(343.63636deg)
}

.kaleidoscope.n22 .tile .image {
	-webkit-transform: rotate(8.18182deg);
	transform: rotate(8.18182deg)
}

.kaleidoscope.n23 .tile {
	display: block
}

.kaleidoscope.n23 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n23 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n23 .tile.t2 {
	-webkit-transform: rotate(15.65217deg);
	transform: rotate(15.65217deg)
}

.kaleidoscope.n23 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(15.65217deg);
	transform: scale(-1, 1) rotate(15.65217deg)
}

.kaleidoscope.n23 .tile.t4 {
	-webkit-transform: rotate(31.30435deg);
	transform: rotate(31.30435deg)
}

.kaleidoscope.n23 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(31.30435deg);
	transform: scale(-1, 1) rotate(31.30435deg)
}

.kaleidoscope.n23 .tile.t6 {
	-webkit-transform: rotate(46.95652deg);
	transform: rotate(46.95652deg)
}

.kaleidoscope.n23 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(46.95652deg);
	transform: scale(-1, 1) rotate(46.95652deg)
}

.kaleidoscope.n23 .tile.t8 {
	-webkit-transform: rotate(62.6087deg);
	transform: rotate(62.6087deg)
}

.kaleidoscope.n23 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(62.6087deg);
	transform: scale(-1, 1) rotate(62.6087deg)
}

.kaleidoscope.n23 .tile.t10 {
	-webkit-transform: rotate(78.26087deg);
	transform: rotate(78.26087deg)
}

.kaleidoscope.n23 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(78.26087deg);
	transform: scale(-1, 1) rotate(78.26087deg)
}

.kaleidoscope.n23 .tile.t12 {
	-webkit-transform: rotate(93.91304deg);
	transform: rotate(93.91304deg)
}

.kaleidoscope.n23 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(93.91304deg);
	transform: scale(-1, 1) rotate(93.91304deg)
}

.kaleidoscope.n23 .tile.t14 {
	-webkit-transform: rotate(109.56522deg);
	transform: rotate(109.56522deg)
}

.kaleidoscope.n23 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(109.56522deg);
	transform: scale(-1, 1) rotate(109.56522deg)
}

.kaleidoscope.n23 .tile.t16 {
	-webkit-transform: rotate(125.21739deg);
	transform: rotate(125.21739deg)
}

.kaleidoscope.n23 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(125.21739deg);
	transform: scale(-1, 1) rotate(125.21739deg)
}

.kaleidoscope.n23 .tile.t18 {
	-webkit-transform: rotate(140.86957deg);
	transform: rotate(140.86957deg)
}

.kaleidoscope.n23 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(140.86957deg);
	transform: scale(-1, 1) rotate(140.86957deg)
}

.kaleidoscope.n23 .tile.t20 {
	-webkit-transform: rotate(156.52174deg);
	transform: rotate(156.52174deg)
}

.kaleidoscope.n23 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(156.52174deg);
	transform: scale(-1, 1) rotate(156.52174deg)
}

.kaleidoscope.n23 .tile.t22 {
	-webkit-transform: rotate(172.17391deg);
	transform: rotate(172.17391deg)
}

.kaleidoscope.n23 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(172.17391deg);
	transform: scale(-1, 1) rotate(172.17391deg)
}

.kaleidoscope.n23 .tile.t24 {
	-webkit-transform: rotate(187.82609deg);
	transform: rotate(187.82609deg)
}

.kaleidoscope.n23 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(187.82609deg);
	transform: scale(-1, 1) rotate(187.82609deg)
}

.kaleidoscope.n23 .tile.t26 {
	-webkit-transform: rotate(203.47826deg);
	transform: rotate(203.47826deg)
}

.kaleidoscope.n23 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(203.47826deg);
	transform: scale(-1, 1) rotate(203.47826deg)
}

.kaleidoscope.n23 .tile.t28 {
	-webkit-transform: rotate(219.13043deg);
	transform: rotate(219.13043deg)
}

.kaleidoscope.n23 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(219.13043deg);
	transform: scale(-1, 1) rotate(219.13043deg)
}

.kaleidoscope.n23 .tile.t30 {
	-webkit-transform: rotate(234.78261deg);
	transform: rotate(234.78261deg)
}

.kaleidoscope.n23 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(234.78261deg);
	transform: scale(-1, 1) rotate(234.78261deg)
}

.kaleidoscope.n23 .tile.t32 {
	-webkit-transform: rotate(250.43478deg);
	transform: rotate(250.43478deg)
}

.kaleidoscope.n23 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(250.43478deg);
	transform: scale(-1, 1) rotate(250.43478deg)
}

.kaleidoscope.n23 .tile.t34 {
	-webkit-transform: rotate(266.08696deg);
	transform: rotate(266.08696deg)
}

.kaleidoscope.n23 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(266.08696deg);
	transform: scale(-1, 1) rotate(266.08696deg)
}

.kaleidoscope.n23 .tile.t36 {
	-webkit-transform: rotate(281.73913deg);
	transform: rotate(281.73913deg)
}

.kaleidoscope.n23 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(281.73913deg);
	transform: scale(-1, 1) rotate(281.73913deg)
}

.kaleidoscope.n23 .tile.t38 {
	-webkit-transform: rotate(297.3913deg);
	transform: rotate(297.3913deg)
}

.kaleidoscope.n23 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(297.3913deg);
	transform: scale(-1, 1) rotate(297.3913deg)
}

.kaleidoscope.n23 .tile.t40 {
	-webkit-transform: rotate(313.04348deg);
	transform: rotate(313.04348deg)
}

.kaleidoscope.n23 .tile.t41 {
	-webkit-transform: scale(-1, 1) rotate(313.04348deg);
	transform: scale(-1, 1) rotate(313.04348deg)
}

.kaleidoscope.n23 .tile.t42 {
	-webkit-transform: rotate(328.69565deg);
	transform: rotate(328.69565deg)
}

.kaleidoscope.n23 .tile.t43 {
	-webkit-transform: scale(-1, 1) rotate(328.69565deg);
	transform: scale(-1, 1) rotate(328.69565deg)
}

.kaleidoscope.n23 .tile.t44 {
	-webkit-transform: rotate(344.34783deg);
	transform: rotate(344.34783deg)
}

.kaleidoscope.n23 .tile.t45 {
	-webkit-transform: scale(-1, 1) rotate(344.34783deg);
	transform: scale(-1, 1) rotate(344.34783deg)
}

.kaleidoscope.n23 .tile .image {
	-webkit-transform: rotate(7.82609deg);
	transform: rotate(7.82609deg)
}

.kaleidoscope.n24 .tile {
	display: block
}

.kaleidoscope.n24 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n24 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n24 .tile.t2 {
	-webkit-transform: rotate(15deg);
	transform: rotate(15deg)
}

.kaleidoscope.n24 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(15deg);
	transform: scale(-1, 1) rotate(15deg)
}

.kaleidoscope.n24 .tile.t4 {
	-webkit-transform: rotate(30deg);
	transform: rotate(30deg)
}

.kaleidoscope.n24 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(30deg);
	transform: scale(-1, 1) rotate(30deg)
}

.kaleidoscope.n24 .tile.t6 {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg)
}

.kaleidoscope.n24 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(45deg);
	transform: scale(-1, 1) rotate(45deg)
}

.kaleidoscope.n24 .tile.t8 {
	-webkit-transform: rotate(60deg);
	transform: rotate(60deg)
}

.kaleidoscope.n24 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(60deg);
	transform: scale(-1, 1) rotate(60deg)
}

.kaleidoscope.n24 .tile.t10 {
	-webkit-transform: rotate(75deg);
	transform: rotate(75deg)
}

.kaleidoscope.n24 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(75deg);
	transform: scale(-1, 1) rotate(75deg)
}

.kaleidoscope.n24 .tile.t12 {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.kaleidoscope.n24 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(90deg);
	transform: scale(-1, 1) rotate(90deg)
}

.kaleidoscope.n24 .tile.t14 {
	-webkit-transform: rotate(105deg);
	transform: rotate(105deg)
}

.kaleidoscope.n24 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(105deg);
	transform: scale(-1, 1) rotate(105deg)
}

.kaleidoscope.n24 .tile.t16 {
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg)
}

.kaleidoscope.n24 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(120deg);
	transform: scale(-1, 1) rotate(120deg)
}

.kaleidoscope.n24 .tile.t18 {
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg)
}

.kaleidoscope.n24 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(135deg);
	transform: scale(-1, 1) rotate(135deg)
}

.kaleidoscope.n24 .tile.t20 {
	-webkit-transform: rotate(150deg);
	transform: rotate(150deg)
}

.kaleidoscope.n24 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(150deg);
	transform: scale(-1, 1) rotate(150deg)
}

.kaleidoscope.n24 .tile.t22 {
	-webkit-transform: rotate(165deg);
	transform: rotate(165deg)
}

.kaleidoscope.n24 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(165deg);
	transform: scale(-1, 1) rotate(165deg)
}

.kaleidoscope.n24 .tile.t24 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n24 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n24 .tile.t26 {
	-webkit-transform: rotate(195deg);
	transform: rotate(195deg)
}

.kaleidoscope.n24 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(195deg);
	transform: scale(-1, 1) rotate(195deg)
}

.kaleidoscope.n24 .tile.t28 {
	-webkit-transform: rotate(210deg);
	transform: rotate(210deg)
}

.kaleidoscope.n24 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(210deg);
	transform: scale(-1, 1) rotate(210deg)
}

.kaleidoscope.n24 .tile.t30 {
	-webkit-transform: rotate(225deg);
	transform: rotate(225deg)
}

.kaleidoscope.n24 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(225deg);
	transform: scale(-1, 1) rotate(225deg)
}

.kaleidoscope.n24 .tile.t32 {
	-webkit-transform: rotate(240deg);
	transform: rotate(240deg)
}

.kaleidoscope.n24 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(240deg);
	transform: scale(-1, 1) rotate(240deg)
}

.kaleidoscope.n24 .tile.t34 {
	-webkit-transform: rotate(255deg);
	transform: rotate(255deg)
}

.kaleidoscope.n24 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(255deg);
	transform: scale(-1, 1) rotate(255deg)
}

.kaleidoscope.n24 .tile.t36 {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg)
}

.kaleidoscope.n24 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(270deg);
	transform: scale(-1, 1) rotate(270deg)
}

.kaleidoscope.n24 .tile.t38 {
	-webkit-transform: rotate(285deg);
	transform: rotate(285deg)
}

.kaleidoscope.n24 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(285deg);
	transform: scale(-1, 1) rotate(285deg)
}

.kaleidoscope.n24 .tile.t40 {
	-webkit-transform: rotate(300deg);
	transform: rotate(300deg)
}

.kaleidoscope.n24 .tile.t41 {
	-webkit-transform: scale(-1, 1) rotate(300deg);
	transform: scale(-1, 1) rotate(300deg)
}

.kaleidoscope.n24 .tile.t42 {
	-webkit-transform: rotate(315deg);
	transform: rotate(315deg)
}

.kaleidoscope.n24 .tile.t43 {
	-webkit-transform: scale(-1, 1) rotate(315deg);
	transform: scale(-1, 1) rotate(315deg)
}

.kaleidoscope.n24 .tile.t44 {
	-webkit-transform: rotate(330deg);
	transform: rotate(330deg)
}

.kaleidoscope.n24 .tile.t45 {
	-webkit-transform: scale(-1, 1) rotate(330deg);
	transform: scale(-1, 1) rotate(330deg)
}

.kaleidoscope.n24 .tile.t46 {
	-webkit-transform: rotate(345deg);
	transform: rotate(345deg)
}

.kaleidoscope.n24 .tile.t47 {
	-webkit-transform: scale(-1, 1) rotate(345deg);
	transform: scale(-1, 1) rotate(345deg)
}

.kaleidoscope.n24 .tile .image {
	-webkit-transform: rotate(7.5deg);
	transform: rotate(7.5deg)
}

.kaleidoscope.n25 .tile {
	display: block
}

.kaleidoscope.n25 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n25 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n25 .tile.t2 {
	-webkit-transform: rotate(14.4deg);
	transform: rotate(14.4deg)
}

.kaleidoscope.n25 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(14.4deg);
	transform: scale(-1, 1) rotate(14.4deg)
}

.kaleidoscope.n25 .tile.t4 {
	-webkit-transform: rotate(28.8deg);
	transform: rotate(28.8deg)
}

.kaleidoscope.n25 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(28.8deg);
	transform: scale(-1, 1) rotate(28.8deg)
}

.kaleidoscope.n25 .tile.t6 {
	-webkit-transform: rotate(43.2deg);
	transform: rotate(43.2deg)
}

.kaleidoscope.n25 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(43.2deg);
	transform: scale(-1, 1) rotate(43.2deg)
}

.kaleidoscope.n25 .tile.t8 {
	-webkit-transform: rotate(57.6deg);
	transform: rotate(57.6deg)
}

.kaleidoscope.n25 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(57.6deg);
	transform: scale(-1, 1) rotate(57.6deg)
}

.kaleidoscope.n25 .tile.t10 {
	-webkit-transform: rotate(72deg);
	transform: rotate(72deg)
}

.kaleidoscope.n25 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(72deg);
	transform: scale(-1, 1) rotate(72deg)
}

.kaleidoscope.n25 .tile.t12 {
	-webkit-transform: rotate(86.4deg);
	transform: rotate(86.4deg)
}

.kaleidoscope.n25 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(86.4deg);
	transform: scale(-1, 1) rotate(86.4deg)
}

.kaleidoscope.n25 .tile.t14 {
	-webkit-transform: rotate(100.8deg);
	transform: rotate(100.8deg)
}

.kaleidoscope.n25 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(100.8deg);
	transform: scale(-1, 1) rotate(100.8deg)
}

.kaleidoscope.n25 .tile.t16 {
	-webkit-transform: rotate(115.2deg);
	transform: rotate(115.2deg)
}

.kaleidoscope.n25 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(115.2deg);
	transform: scale(-1, 1) rotate(115.2deg)
}

.kaleidoscope.n25 .tile.t18 {
	-webkit-transform: rotate(129.6deg);
	transform: rotate(129.6deg)
}

.kaleidoscope.n25 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(129.6deg);
	transform: scale(-1, 1) rotate(129.6deg)
}

.kaleidoscope.n25 .tile.t20 {
	-webkit-transform: rotate(144deg);
	transform: rotate(144deg)
}

.kaleidoscope.n25 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(144deg);
	transform: scale(-1, 1) rotate(144deg)
}

.kaleidoscope.n25 .tile.t22 {
	-webkit-transform: rotate(158.4deg);
	transform: rotate(158.4deg)
}

.kaleidoscope.n25 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(158.4deg);
	transform: scale(-1, 1) rotate(158.4deg)
}

.kaleidoscope.n25 .tile.t24 {
	-webkit-transform: rotate(172.8deg);
	transform: rotate(172.8deg)
}

.kaleidoscope.n25 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(172.8deg);
	transform: scale(-1, 1) rotate(172.8deg)
}

.kaleidoscope.n25 .tile.t26 {
	-webkit-transform: rotate(187.2deg);
	transform: rotate(187.2deg)
}

.kaleidoscope.n25 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(187.2deg);
	transform: scale(-1, 1) rotate(187.2deg)
}

.kaleidoscope.n25 .tile.t28 {
	-webkit-transform: rotate(201.6deg);
	transform: rotate(201.6deg)
}

.kaleidoscope.n25 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(201.6deg);
	transform: scale(-1, 1) rotate(201.6deg)
}

.kaleidoscope.n25 .tile.t30 {
	-webkit-transform: rotate(216.0deg);
	transform: rotate(216.0deg)
}

.kaleidoscope.n25 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(216.0deg);
	transform: scale(-1, 1) rotate(216.0deg)
}

.kaleidoscope.n25 .tile.t32 {
	-webkit-transform: rotate(230.4deg);
	transform: rotate(230.4deg)
}

.kaleidoscope.n25 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(230.4deg);
	transform: scale(-1, 1) rotate(230.4deg)
}

.kaleidoscope.n25 .tile.t34 {
	-webkit-transform: rotate(244.8deg);
	transform: rotate(244.8deg)
}

.kaleidoscope.n25 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(244.8deg);
	transform: scale(-1, 1) rotate(244.8deg)
}

.kaleidoscope.n25 .tile.t36 {
	-webkit-transform: rotate(259.2deg);
	transform: rotate(259.2deg)
}

.kaleidoscope.n25 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(259.2deg);
	transform: scale(-1, 1) rotate(259.2deg)
}

.kaleidoscope.n25 .tile.t38 {
	-webkit-transform: rotate(273.6deg);
	transform: rotate(273.6deg)
}

.kaleidoscope.n25 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(273.6deg);
	transform: scale(-1, 1) rotate(273.6deg)
}

.kaleidoscope.n25 .tile.t40 {
	-webkit-transform: rotate(288deg);
	transform: rotate(288deg)
}

.kaleidoscope.n25 .tile.t41 {
	-webkit-transform: scale(-1, 1) rotate(288deg);
	transform: scale(-1, 1) rotate(288deg)
}

.kaleidoscope.n25 .tile.t42 {
	-webkit-transform: rotate(302.4deg);
	transform: rotate(302.4deg)
}

.kaleidoscope.n25 .tile.t43 {
	-webkit-transform: scale(-1, 1) rotate(302.4deg);
	transform: scale(-1, 1) rotate(302.4deg)
}

.kaleidoscope.n25 .tile.t44 {
	-webkit-transform: rotate(316.8deg);
	transform: rotate(316.8deg)
}

.kaleidoscope.n25 .tile.t45 {
	-webkit-transform: scale(-1, 1) rotate(316.8deg);
	transform: scale(-1, 1) rotate(316.8deg)
}

.kaleidoscope.n25 .tile.t46 {
	-webkit-transform: rotate(331.2deg);
	transform: rotate(331.2deg)
}

.kaleidoscope.n25 .tile.t47 {
	-webkit-transform: scale(-1, 1) rotate(331.2deg);
	transform: scale(-1, 1) rotate(331.2deg)
}

.kaleidoscope.n25 .tile.t48 {
	-webkit-transform: rotate(345.6deg);
	transform: rotate(345.6deg)
}

.kaleidoscope.n25 .tile.t49 {
	-webkit-transform: scale(-1, 1) rotate(345.6deg);
	transform: scale(-1, 1) rotate(345.6deg)
}

.kaleidoscope.n25 .tile .image {
	-webkit-transform: rotate(7.2deg);
	transform: rotate(7.2deg)
}

.kaleidoscope.n26 .tile {
	display: block
}

.kaleidoscope.n26 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n26 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n26 .tile.t2 {
	-webkit-transform: rotate(13.84615deg);
	transform: rotate(13.84615deg)
}

.kaleidoscope.n26 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(13.84615deg);
	transform: scale(-1, 1) rotate(13.84615deg)
}

.kaleidoscope.n26 .tile.t4 {
	-webkit-transform: rotate(27.69231deg);
	transform: rotate(27.69231deg)
}

.kaleidoscope.n26 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(27.69231deg);
	transform: scale(-1, 1) rotate(27.69231deg)
}

.kaleidoscope.n26 .tile.t6 {
	-webkit-transform: rotate(41.53846deg);
	transform: rotate(41.53846deg)
}

.kaleidoscope.n26 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(41.53846deg);
	transform: scale(-1, 1) rotate(41.53846deg)
}

.kaleidoscope.n26 .tile.t8 {
	-webkit-transform: rotate(55.38462deg);
	transform: rotate(55.38462deg)
}

.kaleidoscope.n26 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(55.38462deg);
	transform: scale(-1, 1) rotate(55.38462deg)
}

.kaleidoscope.n26 .tile.t10 {
	-webkit-transform: rotate(69.23077deg);
	transform: rotate(69.23077deg)
}

.kaleidoscope.n26 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(69.23077deg);
	transform: scale(-1, 1) rotate(69.23077deg)
}

.kaleidoscope.n26 .tile.t12 {
	-webkit-transform: rotate(83.07692deg);
	transform: rotate(83.07692deg)
}

.kaleidoscope.n26 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(83.07692deg);
	transform: scale(-1, 1) rotate(83.07692deg)
}

.kaleidoscope.n26 .tile.t14 {
	-webkit-transform: rotate(96.92308deg);
	transform: rotate(96.92308deg)
}

.kaleidoscope.n26 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(96.92308deg);
	transform: scale(-1, 1) rotate(96.92308deg)
}

.kaleidoscope.n26 .tile.t16 {
	-webkit-transform: rotate(110.76923deg);
	transform: rotate(110.76923deg)
}

.kaleidoscope.n26 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(110.76923deg);
	transform: scale(-1, 1) rotate(110.76923deg)
}

.kaleidoscope.n26 .tile.t18 {
	-webkit-transform: rotate(124.61538deg);
	transform: rotate(124.61538deg)
}

.kaleidoscope.n26 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(124.61538deg);
	transform: scale(-1, 1) rotate(124.61538deg)
}

.kaleidoscope.n26 .tile.t20 {
	-webkit-transform: rotate(138.46154deg);
	transform: rotate(138.46154deg)
}

.kaleidoscope.n26 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(138.46154deg);
	transform: scale(-1, 1) rotate(138.46154deg)
}

.kaleidoscope.n26 .tile.t22 {
	-webkit-transform: rotate(152.30769deg);
	transform: rotate(152.30769deg)
}

.kaleidoscope.n26 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(152.30769deg);
	transform: scale(-1, 1) rotate(152.30769deg)
}

.kaleidoscope.n26 .tile.t24 {
	-webkit-transform: rotate(166.15385deg);
	transform: rotate(166.15385deg)
}

.kaleidoscope.n26 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(166.15385deg);
	transform: scale(-1, 1) rotate(166.15385deg)
}

.kaleidoscope.n26 .tile.t26 {
	-webkit-transform: rotate(180.0deg);
	transform: rotate(180.0deg)
}

.kaleidoscope.n26 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(180.0deg);
	transform: scale(-1, 1) rotate(180.0deg)
}

.kaleidoscope.n26 .tile.t28 {
	-webkit-transform: rotate(193.84615deg);
	transform: rotate(193.84615deg)
}

.kaleidoscope.n26 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(193.84615deg);
	transform: scale(-1, 1) rotate(193.84615deg)
}

.kaleidoscope.n26 .tile.t30 {
	-webkit-transform: rotate(207.69231deg);
	transform: rotate(207.69231deg)
}

.kaleidoscope.n26 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(207.69231deg);
	transform: scale(-1, 1) rotate(207.69231deg)
}

.kaleidoscope.n26 .tile.t32 {
	-webkit-transform: rotate(221.53846deg);
	transform: rotate(221.53846deg)
}

.kaleidoscope.n26 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(221.53846deg);
	transform: scale(-1, 1) rotate(221.53846deg)
}

.kaleidoscope.n26 .tile.t34 {
	-webkit-transform: rotate(235.38462deg);
	transform: rotate(235.38462deg)
}

.kaleidoscope.n26 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(235.38462deg);
	transform: scale(-1, 1) rotate(235.38462deg)
}

.kaleidoscope.n26 .tile.t36 {
	-webkit-transform: rotate(249.23077deg);
	transform: rotate(249.23077deg)
}

.kaleidoscope.n26 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(249.23077deg);
	transform: scale(-1, 1) rotate(249.23077deg)
}

.kaleidoscope.n26 .tile.t38 {
	-webkit-transform: rotate(263.07692deg);
	transform: rotate(263.07692deg)
}

.kaleidoscope.n26 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(263.07692deg);
	transform: scale(-1, 1) rotate(263.07692deg)
}

.kaleidoscope.n26 .tile.t40 {
	-webkit-transform: rotate(276.92308deg);
	transform: rotate(276.92308deg)
}

.kaleidoscope.n26 .tile.t41 {
	-webkit-transform: scale(-1, 1) rotate(276.92308deg);
	transform: scale(-1, 1) rotate(276.92308deg)
}

.kaleidoscope.n26 .tile.t42 {
	-webkit-transform: rotate(290.76923deg);
	transform: rotate(290.76923deg)
}

.kaleidoscope.n26 .tile.t43 {
	-webkit-transform: scale(-1, 1) rotate(290.76923deg);
	transform: scale(-1, 1) rotate(290.76923deg)
}

.kaleidoscope.n26 .tile.t44 {
	-webkit-transform: rotate(304.61538deg);
	transform: rotate(304.61538deg)
}

.kaleidoscope.n26 .tile.t45 {
	-webkit-transform: scale(-1, 1) rotate(304.61538deg);
	transform: scale(-1, 1) rotate(304.61538deg)
}

.kaleidoscope.n26 .tile.t46 {
	-webkit-transform: rotate(318.46154deg);
	transform: rotate(318.46154deg)
}

.kaleidoscope.n26 .tile.t47 {
	-webkit-transform: scale(-1, 1) rotate(318.46154deg);
	transform: scale(-1, 1) rotate(318.46154deg)
}

.kaleidoscope.n26 .tile.t48 {
	-webkit-transform: rotate(332.30769deg);
	transform: rotate(332.30769deg)
}

.kaleidoscope.n26 .tile.t49 {
	-webkit-transform: scale(-1, 1) rotate(332.30769deg);
	transform: scale(-1, 1) rotate(332.30769deg)
}

.kaleidoscope.n26 .tile.t50 {
	-webkit-transform: rotate(346.15385deg);
	transform: rotate(346.15385deg)
}

.kaleidoscope.n26 .tile.t51 {
	-webkit-transform: scale(-1, 1) rotate(346.15385deg);
	transform: scale(-1, 1) rotate(346.15385deg)
}

.kaleidoscope.n26 .tile .image {
	-webkit-transform: rotate(6.92308deg);
	transform: rotate(6.92308deg)
}

.kaleidoscope.n27 .tile {
	display: block
}

.kaleidoscope.n27 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n27 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n27 .tile.t2 {
	-webkit-transform: rotate(13.33333deg);
	transform: rotate(13.33333deg)
}

.kaleidoscope.n27 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(13.33333deg);
	transform: scale(-1, 1) rotate(13.33333deg)
}

.kaleidoscope.n27 .tile.t4 {
	-webkit-transform: rotate(26.66667deg);
	transform: rotate(26.66667deg)
}

.kaleidoscope.n27 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(26.66667deg);
	transform: scale(-1, 1) rotate(26.66667deg)
}

.kaleidoscope.n27 .tile.t6 {
	-webkit-transform: rotate(40deg);
	transform: rotate(40deg)
}

.kaleidoscope.n27 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(40deg);
	transform: scale(-1, 1) rotate(40deg)
}

.kaleidoscope.n27 .tile.t8 {
	-webkit-transform: rotate(53.33333deg);
	transform: rotate(53.33333deg)
}

.kaleidoscope.n27 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(53.33333deg);
	transform: scale(-1, 1) rotate(53.33333deg)
}

.kaleidoscope.n27 .tile.t10 {
	-webkit-transform: rotate(66.66667deg);
	transform: rotate(66.66667deg)
}

.kaleidoscope.n27 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(66.66667deg);
	transform: scale(-1, 1) rotate(66.66667deg)
}

.kaleidoscope.n27 .tile.t12 {
	-webkit-transform: rotate(80.0deg);
	transform: rotate(80.0deg)
}

.kaleidoscope.n27 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(80.0deg);
	transform: scale(-1, 1) rotate(80.0deg)
}

.kaleidoscope.n27 .tile.t14 {
	-webkit-transform: rotate(93.33333deg);
	transform: rotate(93.33333deg)
}

.kaleidoscope.n27 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(93.33333deg);
	transform: scale(-1, 1) rotate(93.33333deg)
}

.kaleidoscope.n27 .tile.t16 {
	-webkit-transform: rotate(106.66667deg);
	transform: rotate(106.66667deg)
}

.kaleidoscope.n27 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(106.66667deg);
	transform: scale(-1, 1) rotate(106.66667deg)
}

.kaleidoscope.n27 .tile.t18 {
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg)
}

.kaleidoscope.n27 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(120deg);
	transform: scale(-1, 1) rotate(120deg)
}

.kaleidoscope.n27 .tile.t20 {
	-webkit-transform: rotate(133.33333deg);
	transform: rotate(133.33333deg)
}

.kaleidoscope.n27 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(133.33333deg);
	transform: scale(-1, 1) rotate(133.33333deg)
}

.kaleidoscope.n27 .tile.t22 {
	-webkit-transform: rotate(146.66667deg);
	transform: rotate(146.66667deg)
}

.kaleidoscope.n27 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(146.66667deg);
	transform: scale(-1, 1) rotate(146.66667deg)
}

.kaleidoscope.n27 .tile.t24 {
	-webkit-transform: rotate(160deg);
	transform: rotate(160deg)
}

.kaleidoscope.n27 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(160deg);
	transform: scale(-1, 1) rotate(160deg)
}

.kaleidoscope.n27 .tile.t26 {
	-webkit-transform: rotate(173.33333deg);
	transform: rotate(173.33333deg)
}

.kaleidoscope.n27 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(173.33333deg);
	transform: scale(-1, 1) rotate(173.33333deg)
}

.kaleidoscope.n27 .tile.t28 {
	-webkit-transform: rotate(186.66667deg);
	transform: rotate(186.66667deg)
}

.kaleidoscope.n27 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(186.66667deg);
	transform: scale(-1, 1) rotate(186.66667deg)
}

.kaleidoscope.n27 .tile.t30 {
	-webkit-transform: rotate(200.0deg);
	transform: rotate(200.0deg)
}

.kaleidoscope.n27 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(200.0deg);
	transform: scale(-1, 1) rotate(200.0deg)
}

.kaleidoscope.n27 .tile.t32 {
	-webkit-transform: rotate(213.33333deg);
	transform: rotate(213.33333deg)
}

.kaleidoscope.n27 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(213.33333deg);
	transform: scale(-1, 1) rotate(213.33333deg)
}

.kaleidoscope.n27 .tile.t34 {
	-webkit-transform: rotate(226.66667deg);
	transform: rotate(226.66667deg)
}

.kaleidoscope.n27 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(226.66667deg);
	transform: scale(-1, 1) rotate(226.66667deg)
}

.kaleidoscope.n27 .tile.t36 {
	-webkit-transform: rotate(240.0deg);
	transform: rotate(240.0deg)
}

.kaleidoscope.n27 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(240.0deg);
	transform: scale(-1, 1) rotate(240.0deg)
}

.kaleidoscope.n27 .tile.t38 {
	-webkit-transform: rotate(253.33333deg);
	transform: rotate(253.33333deg)
}

.kaleidoscope.n27 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(253.33333deg);
	transform: scale(-1, 1) rotate(253.33333deg)
}

.kaleidoscope.n27 .tile.t40 {
	-webkit-transform: rotate(266.66667deg);
	transform: rotate(266.66667deg)
}

.kaleidoscope.n27 .tile.t41 {
	-webkit-transform: scale(-1, 1) rotate(266.66667deg);
	transform: scale(-1, 1) rotate(266.66667deg)
}

.kaleidoscope.n27 .tile.t42 {
	-webkit-transform: rotate(280.0deg);
	transform: rotate(280.0deg)
}

.kaleidoscope.n27 .tile.t43 {
	-webkit-transform: scale(-1, 1) rotate(280.0deg);
	transform: scale(-1, 1) rotate(280.0deg)
}

.kaleidoscope.n27 .tile.t44 {
	-webkit-transform: rotate(293.33333deg);
	transform: rotate(293.33333deg)
}

.kaleidoscope.n27 .tile.t45 {
	-webkit-transform: scale(-1, 1) rotate(293.33333deg);
	transform: scale(-1, 1) rotate(293.33333deg)
}

.kaleidoscope.n27 .tile.t46 {
	-webkit-transform: rotate(306.66667deg);
	transform: rotate(306.66667deg)
}

.kaleidoscope.n27 .tile.t47 {
	-webkit-transform: scale(-1, 1) rotate(306.66667deg);
	transform: scale(-1, 1) rotate(306.66667deg)
}

.kaleidoscope.n27 .tile.t48 {
	-webkit-transform: rotate(320deg);
	transform: rotate(320deg)
}

.kaleidoscope.n27 .tile.t49 {
	-webkit-transform: scale(-1, 1) rotate(320deg);
	transform: scale(-1, 1) rotate(320deg)
}

.kaleidoscope.n27 .tile.t50 {
	-webkit-transform: rotate(333.33333deg);
	transform: rotate(333.33333deg)
}

.kaleidoscope.n27 .tile.t51 {
	-webkit-transform: scale(-1, 1) rotate(333.33333deg);
	transform: scale(-1, 1) rotate(333.33333deg)
}

.kaleidoscope.n27 .tile.t52 {
	-webkit-transform: rotate(346.66667deg);
	transform: rotate(346.66667deg)
}

.kaleidoscope.n27 .tile.t53 {
	-webkit-transform: scale(-1, 1) rotate(346.66667deg);
	transform: scale(-1, 1) rotate(346.66667deg)
}

.kaleidoscope.n27 .tile .image {
	-webkit-transform: rotate(6.66667deg);
	transform: rotate(6.66667deg)
}

.kaleidoscope.n28 .tile {
	display: block
}

.kaleidoscope.n28 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n28 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n28 .tile.t2 {
	-webkit-transform: rotate(12.85714deg);
	transform: rotate(12.85714deg)
}

.kaleidoscope.n28 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(12.85714deg);
	transform: scale(-1, 1) rotate(12.85714deg)
}

.kaleidoscope.n28 .tile.t4 {
	-webkit-transform: rotate(25.71429deg);
	transform: rotate(25.71429deg)
}

.kaleidoscope.n28 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(25.71429deg);
	transform: scale(-1, 1) rotate(25.71429deg)
}

.kaleidoscope.n28 .tile.t6 {
	-webkit-transform: rotate(38.57143deg);
	transform: rotate(38.57143deg)
}

.kaleidoscope.n28 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(38.57143deg);
	transform: scale(-1, 1) rotate(38.57143deg)
}

.kaleidoscope.n28 .tile.t8 {
	-webkit-transform: rotate(51.42857deg);
	transform: rotate(51.42857deg)
}

.kaleidoscope.n28 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(51.42857deg);
	transform: scale(-1, 1) rotate(51.42857deg)
}

.kaleidoscope.n28 .tile.t10 {
	-webkit-transform: rotate(64.28571deg);
	transform: rotate(64.28571deg)
}

.kaleidoscope.n28 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(64.28571deg);
	transform: scale(-1, 1) rotate(64.28571deg)
}

.kaleidoscope.n28 .tile.t12 {
	-webkit-transform: rotate(77.14286deg);
	transform: rotate(77.14286deg)
}

.kaleidoscope.n28 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(77.14286deg);
	transform: scale(-1, 1) rotate(77.14286deg)
}

.kaleidoscope.n28 .tile.t14 {
	-webkit-transform: rotate(90.0deg);
	transform: rotate(90.0deg)
}

.kaleidoscope.n28 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(90.0deg);
	transform: scale(-1, 1) rotate(90.0deg)
}

.kaleidoscope.n28 .tile.t16 {
	-webkit-transform: rotate(102.85714deg);
	transform: rotate(102.85714deg)
}

.kaleidoscope.n28 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(102.85714deg);
	transform: scale(-1, 1) rotate(102.85714deg)
}

.kaleidoscope.n28 .tile.t18 {
	-webkit-transform: rotate(115.71429deg);
	transform: rotate(115.71429deg)
}

.kaleidoscope.n28 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(115.71429deg);
	transform: scale(-1, 1) rotate(115.71429deg)
}

.kaleidoscope.n28 .tile.t20 {
	-webkit-transform: rotate(128.57143deg);
	transform: rotate(128.57143deg)
}

.kaleidoscope.n28 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(128.57143deg);
	transform: scale(-1, 1) rotate(128.57143deg)
}

.kaleidoscope.n28 .tile.t22 {
	-webkit-transform: rotate(141.42857deg);
	transform: rotate(141.42857deg)
}

.kaleidoscope.n28 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(141.42857deg);
	transform: scale(-1, 1) rotate(141.42857deg)
}

.kaleidoscope.n28 .tile.t24 {
	-webkit-transform: rotate(154.28571deg);
	transform: rotate(154.28571deg)
}

.kaleidoscope.n28 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(154.28571deg);
	transform: scale(-1, 1) rotate(154.28571deg)
}

.kaleidoscope.n28 .tile.t26 {
	-webkit-transform: rotate(167.14286deg);
	transform: rotate(167.14286deg)
}

.kaleidoscope.n28 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(167.14286deg);
	transform: scale(-1, 1) rotate(167.14286deg)
}

.kaleidoscope.n28 .tile.t28 {
	-webkit-transform: rotate(180.0deg);
	transform: rotate(180.0deg)
}

.kaleidoscope.n28 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(180.0deg);
	transform: scale(-1, 1) rotate(180.0deg)
}

.kaleidoscope.n28 .tile.t30 {
	-webkit-transform: rotate(192.85714deg);
	transform: rotate(192.85714deg)
}

.kaleidoscope.n28 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(192.85714deg);
	transform: scale(-1, 1) rotate(192.85714deg)
}

.kaleidoscope.n28 .tile.t32 {
	-webkit-transform: rotate(205.71429deg);
	transform: rotate(205.71429deg)
}

.kaleidoscope.n28 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(205.71429deg);
	transform: scale(-1, 1) rotate(205.71429deg)
}

.kaleidoscope.n28 .tile.t34 {
	-webkit-transform: rotate(218.57143deg);
	transform: rotate(218.57143deg)
}

.kaleidoscope.n28 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(218.57143deg);
	transform: scale(-1, 1) rotate(218.57143deg)
}

.kaleidoscope.n28 .tile.t36 {
	-webkit-transform: rotate(231.42857deg);
	transform: rotate(231.42857deg)
}

.kaleidoscope.n28 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(231.42857deg);
	transform: scale(-1, 1) rotate(231.42857deg)
}

.kaleidoscope.n28 .tile.t38 {
	-webkit-transform: rotate(244.28571deg);
	transform: rotate(244.28571deg)
}

.kaleidoscope.n28 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(244.28571deg);
	transform: scale(-1, 1) rotate(244.28571deg)
}

.kaleidoscope.n28 .tile.t40 {
	-webkit-transform: rotate(257.14286deg);
	transform: rotate(257.14286deg)
}

.kaleidoscope.n28 .tile.t41 {
	-webkit-transform: scale(-1, 1) rotate(257.14286deg);
	transform: scale(-1, 1) rotate(257.14286deg)
}

.kaleidoscope.n28 .tile.t42 {
	-webkit-transform: rotate(270.0deg);
	transform: rotate(270.0deg)
}

.kaleidoscope.n28 .tile.t43 {
	-webkit-transform: scale(-1, 1) rotate(270.0deg);
	transform: scale(-1, 1) rotate(270.0deg)
}

.kaleidoscope.n28 .tile.t44 {
	-webkit-transform: rotate(282.85714deg);
	transform: rotate(282.85714deg)
}

.kaleidoscope.n28 .tile.t45 {
	-webkit-transform: scale(-1, 1) rotate(282.85714deg);
	transform: scale(-1, 1) rotate(282.85714deg)
}

.kaleidoscope.n28 .tile.t46 {
	-webkit-transform: rotate(295.71429deg);
	transform: rotate(295.71429deg)
}

.kaleidoscope.n28 .tile.t47 {
	-webkit-transform: scale(-1, 1) rotate(295.71429deg);
	transform: scale(-1, 1) rotate(295.71429deg)
}

.kaleidoscope.n28 .tile.t48 {
	-webkit-transform: rotate(308.57143deg);
	transform: rotate(308.57143deg)
}

.kaleidoscope.n28 .tile.t49 {
	-webkit-transform: scale(-1, 1) rotate(308.57143deg);
	transform: scale(-1, 1) rotate(308.57143deg)
}

.kaleidoscope.n28 .tile.t50 {
	-webkit-transform: rotate(321.42857deg);
	transform: rotate(321.42857deg)
}

.kaleidoscope.n28 .tile.t51 {
	-webkit-transform: scale(-1, 1) rotate(321.42857deg);
	transform: scale(-1, 1) rotate(321.42857deg)
}

.kaleidoscope.n28 .tile.t52 {
	-webkit-transform: rotate(334.28571deg);
	transform: rotate(334.28571deg)
}

.kaleidoscope.n28 .tile.t53 {
	-webkit-transform: scale(-1, 1) rotate(334.28571deg);
	transform: scale(-1, 1) rotate(334.28571deg)
}

.kaleidoscope.n28 .tile.t54 {
	-webkit-transform: rotate(347.14286deg);
	transform: rotate(347.14286deg)
}

.kaleidoscope.n28 .tile.t55 {
	-webkit-transform: scale(-1, 1) rotate(347.14286deg);
	transform: scale(-1, 1) rotate(347.14286deg)
}

.kaleidoscope.n28 .tile .image {
	-webkit-transform: rotate(6.42857deg);
	transform: rotate(6.42857deg)
}

.kaleidoscope.n29 .tile {
	display: block
}

.kaleidoscope.n29 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n29 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n29 .tile.t2 {
	-webkit-transform: rotate(12.41379deg);
	transform: rotate(12.41379deg)
}

.kaleidoscope.n29 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(12.41379deg);
	transform: scale(-1, 1) rotate(12.41379deg)
}

.kaleidoscope.n29 .tile.t4 {
	-webkit-transform: rotate(24.82759deg);
	transform: rotate(24.82759deg)
}

.kaleidoscope.n29 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(24.82759deg);
	transform: scale(-1, 1) rotate(24.82759deg)
}

.kaleidoscope.n29 .tile.t6 {
	-webkit-transform: rotate(37.24138deg);
	transform: rotate(37.24138deg)
}

.kaleidoscope.n29 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(37.24138deg);
	transform: scale(-1, 1) rotate(37.24138deg)
}

.kaleidoscope.n29 .tile.t8 {
	-webkit-transform: rotate(49.65517deg);
	transform: rotate(49.65517deg)
}

.kaleidoscope.n29 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(49.65517deg);
	transform: scale(-1, 1) rotate(49.65517deg)
}

.kaleidoscope.n29 .tile.t10 {
	-webkit-transform: rotate(62.06897deg);
	transform: rotate(62.06897deg)
}

.kaleidoscope.n29 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(62.06897deg);
	transform: scale(-1, 1) rotate(62.06897deg)
}

.kaleidoscope.n29 .tile.t12 {
	-webkit-transform: rotate(74.48276deg);
	transform: rotate(74.48276deg)
}

.kaleidoscope.n29 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(74.48276deg);
	transform: scale(-1, 1) rotate(74.48276deg)
}

.kaleidoscope.n29 .tile.t14 {
	-webkit-transform: rotate(86.89655deg);
	transform: rotate(86.89655deg)
}

.kaleidoscope.n29 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(86.89655deg);
	transform: scale(-1, 1) rotate(86.89655deg)
}

.kaleidoscope.n29 .tile.t16 {
	-webkit-transform: rotate(99.31034deg);
	transform: rotate(99.31034deg)
}

.kaleidoscope.n29 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(99.31034deg);
	transform: scale(-1, 1) rotate(99.31034deg)
}

.kaleidoscope.n29 .tile.t18 {
	-webkit-transform: rotate(111.72414deg);
	transform: rotate(111.72414deg)
}

.kaleidoscope.n29 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(111.72414deg);
	transform: scale(-1, 1) rotate(111.72414deg)
}

.kaleidoscope.n29 .tile.t20 {
	-webkit-transform: rotate(124.13793deg);
	transform: rotate(124.13793deg)
}

.kaleidoscope.n29 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(124.13793deg);
	transform: scale(-1, 1) rotate(124.13793deg)
}

.kaleidoscope.n29 .tile.t22 {
	-webkit-transform: rotate(136.55172deg);
	transform: rotate(136.55172deg)
}

.kaleidoscope.n29 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(136.55172deg);
	transform: scale(-1, 1) rotate(136.55172deg)
}

.kaleidoscope.n29 .tile.t24 {
	-webkit-transform: rotate(148.96552deg);
	transform: rotate(148.96552deg)
}

.kaleidoscope.n29 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(148.96552deg);
	transform: scale(-1, 1) rotate(148.96552deg)
}

.kaleidoscope.n29 .tile.t26 {
	-webkit-transform: rotate(161.37931deg);
	transform: rotate(161.37931deg)
}

.kaleidoscope.n29 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(161.37931deg);
	transform: scale(-1, 1) rotate(161.37931deg)
}

.kaleidoscope.n29 .tile.t28 {
	-webkit-transform: rotate(173.7931deg);
	transform: rotate(173.7931deg)
}

.kaleidoscope.n29 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(173.7931deg);
	transform: scale(-1, 1) rotate(173.7931deg)
}

.kaleidoscope.n29 .tile.t30 {
	-webkit-transform: rotate(186.2069deg);
	transform: rotate(186.2069deg)
}

.kaleidoscope.n29 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(186.2069deg);
	transform: scale(-1, 1) rotate(186.2069deg)
}

.kaleidoscope.n29 .tile.t32 {
	-webkit-transform: rotate(198.62069deg);
	transform: rotate(198.62069deg)
}

.kaleidoscope.n29 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(198.62069deg);
	transform: scale(-1, 1) rotate(198.62069deg)
}

.kaleidoscope.n29 .tile.t34 {
	-webkit-transform: rotate(211.03448deg);
	transform: rotate(211.03448deg)
}

.kaleidoscope.n29 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(211.03448deg);
	transform: scale(-1, 1) rotate(211.03448deg)
}

.kaleidoscope.n29 .tile.t36 {
	-webkit-transform: rotate(223.44828deg);
	transform: rotate(223.44828deg)
}

.kaleidoscope.n29 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(223.44828deg);
	transform: scale(-1, 1) rotate(223.44828deg)
}

.kaleidoscope.n29 .tile.t38 {
	-webkit-transform: rotate(235.86207deg);
	transform: rotate(235.86207deg)
}

.kaleidoscope.n29 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(235.86207deg);
	transform: scale(-1, 1) rotate(235.86207deg)
}

.kaleidoscope.n29 .tile.t40 {
	-webkit-transform: rotate(248.27586deg);
	transform: rotate(248.27586deg)
}

.kaleidoscope.n29 .tile.t41 {
	-webkit-transform: scale(-1, 1) rotate(248.27586deg);
	transform: scale(-1, 1) rotate(248.27586deg)
}

.kaleidoscope.n29 .tile.t42 {
	-webkit-transform: rotate(260.68966deg);
	transform: rotate(260.68966deg)
}

.kaleidoscope.n29 .tile.t43 {
	-webkit-transform: scale(-1, 1) rotate(260.68966deg);
	transform: scale(-1, 1) rotate(260.68966deg)
}

.kaleidoscope.n29 .tile.t44 {
	-webkit-transform: rotate(273.10345deg);
	transform: rotate(273.10345deg)
}

.kaleidoscope.n29 .tile.t45 {
	-webkit-transform: scale(-1, 1) rotate(273.10345deg);
	transform: scale(-1, 1) rotate(273.10345deg)
}

.kaleidoscope.n29 .tile.t46 {
	-webkit-transform: rotate(285.51724deg);
	transform: rotate(285.51724deg)
}

.kaleidoscope.n29 .tile.t47 {
	-webkit-transform: scale(-1, 1) rotate(285.51724deg);
	transform: scale(-1, 1) rotate(285.51724deg)
}

.kaleidoscope.n29 .tile.t48 {
	-webkit-transform: rotate(297.93103deg);
	transform: rotate(297.93103deg)
}

.kaleidoscope.n29 .tile.t49 {
	-webkit-transform: scale(-1, 1) rotate(297.93103deg);
	transform: scale(-1, 1) rotate(297.93103deg)
}

.kaleidoscope.n29 .tile.t50 {
	-webkit-transform: rotate(310.34483deg);
	transform: rotate(310.34483deg)
}

.kaleidoscope.n29 .tile.t51 {
	-webkit-transform: scale(-1, 1) rotate(310.34483deg);
	transform: scale(-1, 1) rotate(310.34483deg)
}

.kaleidoscope.n29 .tile.t52 {
	-webkit-transform: rotate(322.75862deg);
	transform: rotate(322.75862deg)
}

.kaleidoscope.n29 .tile.t53 {
	-webkit-transform: scale(-1, 1) rotate(322.75862deg);
	transform: scale(-1, 1) rotate(322.75862deg)
}

.kaleidoscope.n29 .tile.t54 {
	-webkit-transform: rotate(335.17241deg);
	transform: rotate(335.17241deg)
}

.kaleidoscope.n29 .tile.t55 {
	-webkit-transform: scale(-1, 1) rotate(335.17241deg);
	transform: scale(-1, 1) rotate(335.17241deg)
}

.kaleidoscope.n29 .tile.t56 {
	-webkit-transform: rotate(347.58621deg);
	transform: rotate(347.58621deg)
}

.kaleidoscope.n29 .tile.t57 {
	-webkit-transform: scale(-1, 1) rotate(347.58621deg);
	transform: scale(-1, 1) rotate(347.58621deg)
}

.kaleidoscope.n29 .tile .image {
	-webkit-transform: rotate(6.2069deg);
	transform: rotate(6.2069deg)
}

.kaleidoscope.n30 .tile {
	display: block
}

.kaleidoscope.n30 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n30 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n30 .tile.t2 {
	-webkit-transform: rotate(12deg);
	transform: rotate(12deg)
}

.kaleidoscope.n30 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(12deg);
	transform: scale(-1, 1) rotate(12deg)
}

.kaleidoscope.n30 .tile.t4 {
	-webkit-transform: rotate(24deg);
	transform: rotate(24deg)
}

.kaleidoscope.n30 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(24deg);
	transform: scale(-1, 1) rotate(24deg)
}

.kaleidoscope.n30 .tile.t6 {
	-webkit-transform: rotate(36deg);
	transform: rotate(36deg)
}

.kaleidoscope.n30 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(36deg);
	transform: scale(-1, 1) rotate(36deg)
}

.kaleidoscope.n30 .tile.t8 {
	-webkit-transform: rotate(48deg);
	transform: rotate(48deg)
}

.kaleidoscope.n30 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(48deg);
	transform: scale(-1, 1) rotate(48deg)
}

.kaleidoscope.n30 .tile.t10 {
	-webkit-transform: rotate(60deg);
	transform: rotate(60deg)
}

.kaleidoscope.n30 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(60deg);
	transform: scale(-1, 1) rotate(60deg)
}

.kaleidoscope.n30 .tile.t12 {
	-webkit-transform: rotate(72deg);
	transform: rotate(72deg)
}

.kaleidoscope.n30 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(72deg);
	transform: scale(-1, 1) rotate(72deg)
}

.kaleidoscope.n30 .tile.t14 {
	-webkit-transform: rotate(84deg);
	transform: rotate(84deg)
}

.kaleidoscope.n30 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(84deg);
	transform: scale(-1, 1) rotate(84deg)
}

.kaleidoscope.n30 .tile.t16 {
	-webkit-transform: rotate(96deg);
	transform: rotate(96deg)
}

.kaleidoscope.n30 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(96deg);
	transform: scale(-1, 1) rotate(96deg)
}

.kaleidoscope.n30 .tile.t18 {
	-webkit-transform: rotate(108deg);
	transform: rotate(108deg)
}

.kaleidoscope.n30 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(108deg);
	transform: scale(-1, 1) rotate(108deg)
}

.kaleidoscope.n30 .tile.t20 {
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg)
}

.kaleidoscope.n30 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(120deg);
	transform: scale(-1, 1) rotate(120deg)
}

.kaleidoscope.n30 .tile.t22 {
	-webkit-transform: rotate(132deg);
	transform: rotate(132deg)
}

.kaleidoscope.n30 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(132deg);
	transform: scale(-1, 1) rotate(132deg)
}

.kaleidoscope.n30 .tile.t24 {
	-webkit-transform: rotate(144deg);
	transform: rotate(144deg)
}

.kaleidoscope.n30 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(144deg);
	transform: scale(-1, 1) rotate(144deg)
}

.kaleidoscope.n30 .tile.t26 {
	-webkit-transform: rotate(156deg);
	transform: rotate(156deg)
}

.kaleidoscope.n30 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(156deg);
	transform: scale(-1, 1) rotate(156deg)
}

.kaleidoscope.n30 .tile.t28 {
	-webkit-transform: rotate(168deg);
	transform: rotate(168deg)
}

.kaleidoscope.n30 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(168deg);
	transform: scale(-1, 1) rotate(168deg)
}

.kaleidoscope.n30 .tile.t30 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n30 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n30 .tile.t32 {
	-webkit-transform: rotate(192deg);
	transform: rotate(192deg)
}

.kaleidoscope.n30 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(192deg);
	transform: scale(-1, 1) rotate(192deg)
}

.kaleidoscope.n30 .tile.t34 {
	-webkit-transform: rotate(204deg);
	transform: rotate(204deg)
}

.kaleidoscope.n30 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(204deg);
	transform: scale(-1, 1) rotate(204deg)
}

.kaleidoscope.n30 .tile.t36 {
	-webkit-transform: rotate(216deg);
	transform: rotate(216deg)
}

.kaleidoscope.n30 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(216deg);
	transform: scale(-1, 1) rotate(216deg)
}

.kaleidoscope.n30 .tile.t38 {
	-webkit-transform: rotate(228deg);
	transform: rotate(228deg)
}

.kaleidoscope.n30 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(228deg);
	transform: scale(-1, 1) rotate(228deg)
}

.kaleidoscope.n30 .tile.t40 {
	-webkit-transform: rotate(240deg);
	transform: rotate(240deg)
}

.kaleidoscope.n30 .tile.t41 {
	-webkit-transform: scale(-1, 1) rotate(240deg);
	transform: scale(-1, 1) rotate(240deg)
}

.kaleidoscope.n30 .tile.t42 {
	-webkit-transform: rotate(252deg);
	transform: rotate(252deg)
}

.kaleidoscope.n30 .tile.t43 {
	-webkit-transform: scale(-1, 1) rotate(252deg);
	transform: scale(-1, 1) rotate(252deg)
}

.kaleidoscope.n30 .tile.t44 {
	-webkit-transform: rotate(264deg);
	transform: rotate(264deg)
}

.kaleidoscope.n30 .tile.t45 {
	-webkit-transform: scale(-1, 1) rotate(264deg);
	transform: scale(-1, 1) rotate(264deg)
}

.kaleidoscope.n30 .tile.t46 {
	-webkit-transform: rotate(276deg);
	transform: rotate(276deg)
}

.kaleidoscope.n30 .tile.t47 {
	-webkit-transform: scale(-1, 1) rotate(276deg);
	transform: scale(-1, 1) rotate(276deg)
}

.kaleidoscope.n30 .tile.t48 {
	-webkit-transform: rotate(288deg);
	transform: rotate(288deg)
}

.kaleidoscope.n30 .tile.t49 {
	-webkit-transform: scale(-1, 1) rotate(288deg);
	transform: scale(-1, 1) rotate(288deg)
}

.kaleidoscope.n30 .tile.t50 {
	-webkit-transform: rotate(300deg);
	transform: rotate(300deg)
}

.kaleidoscope.n30 .tile.t51 {
	-webkit-transform: scale(-1, 1) rotate(300deg);
	transform: scale(-1, 1) rotate(300deg)
}

.kaleidoscope.n30 .tile.t52 {
	-webkit-transform: rotate(312deg);
	transform: rotate(312deg)
}

.kaleidoscope.n30 .tile.t53 {
	-webkit-transform: scale(-1, 1) rotate(312deg);
	transform: scale(-1, 1) rotate(312deg)
}

.kaleidoscope.n30 .tile.t54 {
	-webkit-transform: rotate(324deg);
	transform: rotate(324deg)
}

.kaleidoscope.n30 .tile.t55 {
	-webkit-transform: scale(-1, 1) rotate(324deg);
	transform: scale(-1, 1) rotate(324deg)
}

.kaleidoscope.n30 .tile.t56 {
	-webkit-transform: rotate(336deg);
	transform: rotate(336deg)
}

.kaleidoscope.n30 .tile.t57 {
	-webkit-transform: scale(-1, 1) rotate(336deg);
	transform: scale(-1, 1) rotate(336deg)
}

.kaleidoscope.n30 .tile.t58 {
	-webkit-transform: rotate(348deg);
	transform: rotate(348deg)
}

.kaleidoscope.n30 .tile.t59 {
	-webkit-transform: scale(-1, 1) rotate(348deg);
	transform: scale(-1, 1) rotate(348deg)
}

.kaleidoscope.n30 .tile .image {
	-webkit-transform: rotate(6deg);
	transform: rotate(6deg)
}

.kaleidoscope.n31 .tile {
	display: block
}

.kaleidoscope.n31 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n31 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n31 .tile.t2 {
	-webkit-transform: rotate(11.6129deg);
	transform: rotate(11.6129deg)
}

.kaleidoscope.n31 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(11.6129deg);
	transform: scale(-1, 1) rotate(11.6129deg)
}

.kaleidoscope.n31 .tile.t4 {
	-webkit-transform: rotate(23.22581deg);
	transform: rotate(23.22581deg)
}

.kaleidoscope.n31 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(23.22581deg);
	transform: scale(-1, 1) rotate(23.22581deg)
}

.kaleidoscope.n31 .tile.t6 {
	-webkit-transform: rotate(34.83871deg);
	transform: rotate(34.83871deg)
}

.kaleidoscope.n31 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(34.83871deg);
	transform: scale(-1, 1) rotate(34.83871deg)
}

.kaleidoscope.n31 .tile.t8 {
	-webkit-transform: rotate(46.45161deg);
	transform: rotate(46.45161deg)
}

.kaleidoscope.n31 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(46.45161deg);
	transform: scale(-1, 1) rotate(46.45161deg)
}

.kaleidoscope.n31 .tile.t10 {
	-webkit-transform: rotate(58.06452deg);
	transform: rotate(58.06452deg)
}

.kaleidoscope.n31 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(58.06452deg);
	transform: scale(-1, 1) rotate(58.06452deg)
}

.kaleidoscope.n31 .tile.t12 {
	-webkit-transform: rotate(69.67742deg);
	transform: rotate(69.67742deg)
}

.kaleidoscope.n31 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(69.67742deg);
	transform: scale(-1, 1) rotate(69.67742deg)
}

.kaleidoscope.n31 .tile.t14 {
	-webkit-transform: rotate(81.29032deg);
	transform: rotate(81.29032deg)
}

.kaleidoscope.n31 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(81.29032deg);
	transform: scale(-1, 1) rotate(81.29032deg)
}

.kaleidoscope.n31 .tile.t16 {
	-webkit-transform: rotate(92.90323deg);
	transform: rotate(92.90323deg)
}

.kaleidoscope.n31 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(92.90323deg);
	transform: scale(-1, 1) rotate(92.90323deg)
}

.kaleidoscope.n31 .tile.t18 {
	-webkit-transform: rotate(104.51613deg);
	transform: rotate(104.51613deg)
}

.kaleidoscope.n31 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(104.51613deg);
	transform: scale(-1, 1) rotate(104.51613deg)
}

.kaleidoscope.n31 .tile.t20 {
	-webkit-transform: rotate(116.12903deg);
	transform: rotate(116.12903deg)
}

.kaleidoscope.n31 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(116.12903deg);
	transform: scale(-1, 1) rotate(116.12903deg)
}

.kaleidoscope.n31 .tile.t22 {
	-webkit-transform: rotate(127.74194deg);
	transform: rotate(127.74194deg)
}

.kaleidoscope.n31 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(127.74194deg);
	transform: scale(-1, 1) rotate(127.74194deg)
}

.kaleidoscope.n31 .tile.t24 {
	-webkit-transform: rotate(139.35484deg);
	transform: rotate(139.35484deg)
}

.kaleidoscope.n31 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(139.35484deg);
	transform: scale(-1, 1) rotate(139.35484deg)
}

.kaleidoscope.n31 .tile.t26 {
	-webkit-transform: rotate(150.96774deg);
	transform: rotate(150.96774deg)
}

.kaleidoscope.n31 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(150.96774deg);
	transform: scale(-1, 1) rotate(150.96774deg)
}

.kaleidoscope.n31 .tile.t28 {
	-webkit-transform: rotate(162.58065deg);
	transform: rotate(162.58065deg)
}

.kaleidoscope.n31 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(162.58065deg);
	transform: scale(-1, 1) rotate(162.58065deg)
}

.kaleidoscope.n31 .tile.t30 {
	-webkit-transform: rotate(174.19355deg);
	transform: rotate(174.19355deg)
}

.kaleidoscope.n31 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(174.19355deg);
	transform: scale(-1, 1) rotate(174.19355deg)
}

.kaleidoscope.n31 .tile.t32 {
	-webkit-transform: rotate(185.80645deg);
	transform: rotate(185.80645deg)
}

.kaleidoscope.n31 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(185.80645deg);
	transform: scale(-1, 1) rotate(185.80645deg)
}

.kaleidoscope.n31 .tile.t34 {
	-webkit-transform: rotate(197.41935deg);
	transform: rotate(197.41935deg)
}

.kaleidoscope.n31 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(197.41935deg);
	transform: scale(-1, 1) rotate(197.41935deg)
}

.kaleidoscope.n31 .tile.t36 {
	-webkit-transform: rotate(209.03226deg);
	transform: rotate(209.03226deg)
}

.kaleidoscope.n31 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(209.03226deg);
	transform: scale(-1, 1) rotate(209.03226deg)
}

.kaleidoscope.n31 .tile.t38 {
	-webkit-transform: rotate(220.64516deg);
	transform: rotate(220.64516deg)
}

.kaleidoscope.n31 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(220.64516deg);
	transform: scale(-1, 1) rotate(220.64516deg)
}

.kaleidoscope.n31 .tile.t40 {
	-webkit-transform: rotate(232.25806deg);
	transform: rotate(232.25806deg)
}

.kaleidoscope.n31 .tile.t41 {
	-webkit-transform: scale(-1, 1) rotate(232.25806deg);
	transform: scale(-1, 1) rotate(232.25806deg)
}

.kaleidoscope.n31 .tile.t42 {
	-webkit-transform: rotate(243.87097deg);
	transform: rotate(243.87097deg)
}

.kaleidoscope.n31 .tile.t43 {
	-webkit-transform: scale(-1, 1) rotate(243.87097deg);
	transform: scale(-1, 1) rotate(243.87097deg)
}

.kaleidoscope.n31 .tile.t44 {
	-webkit-transform: rotate(255.48387deg);
	transform: rotate(255.48387deg)
}

.kaleidoscope.n31 .tile.t45 {
	-webkit-transform: scale(-1, 1) rotate(255.48387deg);
	transform: scale(-1, 1) rotate(255.48387deg)
}

.kaleidoscope.n31 .tile.t46 {
	-webkit-transform: rotate(267.09677deg);
	transform: rotate(267.09677deg)
}

.kaleidoscope.n31 .tile.t47 {
	-webkit-transform: scale(-1, 1) rotate(267.09677deg);
	transform: scale(-1, 1) rotate(267.09677deg)
}

.kaleidoscope.n31 .tile.t48 {
	-webkit-transform: rotate(278.70968deg);
	transform: rotate(278.70968deg)
}

.kaleidoscope.n31 .tile.t49 {
	-webkit-transform: scale(-1, 1) rotate(278.70968deg);
	transform: scale(-1, 1) rotate(278.70968deg)
}

.kaleidoscope.n31 .tile.t50 {
	-webkit-transform: rotate(290.32258deg);
	transform: rotate(290.32258deg)
}

.kaleidoscope.n31 .tile.t51 {
	-webkit-transform: scale(-1, 1) rotate(290.32258deg);
	transform: scale(-1, 1) rotate(290.32258deg)
}

.kaleidoscope.n31 .tile.t52 {
	-webkit-transform: rotate(301.93548deg);
	transform: rotate(301.93548deg)
}

.kaleidoscope.n31 .tile.t53 {
	-webkit-transform: scale(-1, 1) rotate(301.93548deg);
	transform: scale(-1, 1) rotate(301.93548deg)
}

.kaleidoscope.n31 .tile.t54 {
	-webkit-transform: rotate(313.54839deg);
	transform: rotate(313.54839deg)
}

.kaleidoscope.n31 .tile.t55 {
	-webkit-transform: scale(-1, 1) rotate(313.54839deg);
	transform: scale(-1, 1) rotate(313.54839deg)
}

.kaleidoscope.n31 .tile.t56 {
	-webkit-transform: rotate(325.16129deg);
	transform: rotate(325.16129deg)
}

.kaleidoscope.n31 .tile.t57 {
	-webkit-transform: scale(-1, 1) rotate(325.16129deg);
	transform: scale(-1, 1) rotate(325.16129deg)
}

.kaleidoscope.n31 .tile.t58 {
	-webkit-transform: rotate(336.77419deg);
	transform: rotate(336.77419deg)
}

.kaleidoscope.n31 .tile.t59 {
	-webkit-transform: scale(-1, 1) rotate(336.77419deg);
	transform: scale(-1, 1) rotate(336.77419deg)
}

.kaleidoscope.n31 .tile.t60 {
	-webkit-transform: rotate(348.3871deg);
	transform: rotate(348.3871deg)
}

.kaleidoscope.n31 .tile.t61 {
	-webkit-transform: scale(-1, 1) rotate(348.3871deg);
	transform: scale(-1, 1) rotate(348.3871deg)
}

.kaleidoscope.n31 .tile .image {
	-webkit-transform: rotate(5.80645deg);
	transform: rotate(5.80645deg)
}

.kaleidoscope.n32 .tile {
	display: block
}

.kaleidoscope.n32 .tile.t0 {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.kaleidoscope.n32 .tile.t1 {
	-webkit-transform: scale(-1, 1) rotate(0deg);
	transform: scale(-1, 1) rotate(0deg)
}

.kaleidoscope.n32 .tile.t2 {
	-webkit-transform: rotate(11.25deg);
	transform: rotate(11.25deg)
}

.kaleidoscope.n32 .tile.t3 {
	-webkit-transform: scale(-1, 1) rotate(11.25deg);
	transform: scale(-1, 1) rotate(11.25deg)
}

.kaleidoscope.n32 .tile.t4 {
	-webkit-transform: rotate(22.5deg);
	transform: rotate(22.5deg)
}

.kaleidoscope.n32 .tile.t5 {
	-webkit-transform: scale(-1, 1) rotate(22.5deg);
	transform: scale(-1, 1) rotate(22.5deg)
}

.kaleidoscope.n32 .tile.t6 {
	-webkit-transform: rotate(33.75deg);
	transform: rotate(33.75deg)
}

.kaleidoscope.n32 .tile.t7 {
	-webkit-transform: scale(-1, 1) rotate(33.75deg);
	transform: scale(-1, 1) rotate(33.75deg)
}

.kaleidoscope.n32 .tile.t8 {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg)
}

.kaleidoscope.n32 .tile.t9 {
	-webkit-transform: scale(-1, 1) rotate(45deg);
	transform: scale(-1, 1) rotate(45deg)
}

.kaleidoscope.n32 .tile.t10 {
	-webkit-transform: rotate(56.25deg);
	transform: rotate(56.25deg)
}

.kaleidoscope.n32 .tile.t11 {
	-webkit-transform: scale(-1, 1) rotate(56.25deg);
	transform: scale(-1, 1) rotate(56.25deg)
}

.kaleidoscope.n32 .tile.t12 {
	-webkit-transform: rotate(67.5deg);
	transform: rotate(67.5deg)
}

.kaleidoscope.n32 .tile.t13 {
	-webkit-transform: scale(-1, 1) rotate(67.5deg);
	transform: scale(-1, 1) rotate(67.5deg)
}

.kaleidoscope.n32 .tile.t14 {
	-webkit-transform: rotate(78.75deg);
	transform: rotate(78.75deg)
}

.kaleidoscope.n32 .tile.t15 {
	-webkit-transform: scale(-1, 1) rotate(78.75deg);
	transform: scale(-1, 1) rotate(78.75deg)
}

.kaleidoscope.n32 .tile.t16 {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.kaleidoscope.n32 .tile.t17 {
	-webkit-transform: scale(-1, 1) rotate(90deg);
	transform: scale(-1, 1) rotate(90deg)
}

.kaleidoscope.n32 .tile.t18 {
	-webkit-transform: rotate(101.25deg);
	transform: rotate(101.25deg)
}

.kaleidoscope.n32 .tile.t19 {
	-webkit-transform: scale(-1, 1) rotate(101.25deg);
	transform: scale(-1, 1) rotate(101.25deg)
}

.kaleidoscope.n32 .tile.t20 {
	-webkit-transform: rotate(112.5deg);
	transform: rotate(112.5deg)
}

.kaleidoscope.n32 .tile.t21 {
	-webkit-transform: scale(-1, 1) rotate(112.5deg);
	transform: scale(-1, 1) rotate(112.5deg)
}

.kaleidoscope.n32 .tile.t22 {
	-webkit-transform: rotate(123.75deg);
	transform: rotate(123.75deg)
}

.kaleidoscope.n32 .tile.t23 {
	-webkit-transform: scale(-1, 1) rotate(123.75deg);
	transform: scale(-1, 1) rotate(123.75deg)
}

.kaleidoscope.n32 .tile.t24 {
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg)
}

.kaleidoscope.n32 .tile.t25 {
	-webkit-transform: scale(-1, 1) rotate(135deg);
	transform: scale(-1, 1) rotate(135deg)
}

.kaleidoscope.n32 .tile.t26 {
	-webkit-transform: rotate(146.25deg);
	transform: rotate(146.25deg)
}

.kaleidoscope.n32 .tile.t27 {
	-webkit-transform: scale(-1, 1) rotate(146.25deg);
	transform: scale(-1, 1) rotate(146.25deg)
}

.kaleidoscope.n32 .tile.t28 {
	-webkit-transform: rotate(157.5deg);
	transform: rotate(157.5deg)
}

.kaleidoscope.n32 .tile.t29 {
	-webkit-transform: scale(-1, 1) rotate(157.5deg);
	transform: scale(-1, 1) rotate(157.5deg)
}

.kaleidoscope.n32 .tile.t30 {
	-webkit-transform: rotate(168.75deg);
	transform: rotate(168.75deg)
}

.kaleidoscope.n32 .tile.t31 {
	-webkit-transform: scale(-1, 1) rotate(168.75deg);
	transform: scale(-1, 1) rotate(168.75deg)
}

.kaleidoscope.n32 .tile.t32 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.kaleidoscope.n32 .tile.t33 {
	-webkit-transform: scale(-1, 1) rotate(180deg);
	transform: scale(-1, 1) rotate(180deg)
}

.kaleidoscope.n32 .tile.t34 {
	-webkit-transform: rotate(191.25deg);
	transform: rotate(191.25deg)
}

.kaleidoscope.n32 .tile.t35 {
	-webkit-transform: scale(-1, 1) rotate(191.25deg);
	transform: scale(-1, 1) rotate(191.25deg)
}

.kaleidoscope.n32 .tile.t36 {
	-webkit-transform: rotate(202.5deg);
	transform: rotate(202.5deg)
}

.kaleidoscope.n32 .tile.t37 {
	-webkit-transform: scale(-1, 1) rotate(202.5deg);
	transform: scale(-1, 1) rotate(202.5deg)
}

.kaleidoscope.n32 .tile.t38 {
	-webkit-transform: rotate(213.75deg);
	transform: rotate(213.75deg)
}

.kaleidoscope.n32 .tile.t39 {
	-webkit-transform: scale(-1, 1) rotate(213.75deg);
	transform: scale(-1, 1) rotate(213.75deg)
}

.kaleidoscope.n32 .tile.t40 {
	-webkit-transform: rotate(225deg);
	transform: rotate(225deg)
}

.kaleidoscope.n32 .tile.t41 {
	-webkit-transform: scale(-1, 1) rotate(225deg);
	transform: scale(-1, 1) rotate(225deg)
}

.kaleidoscope.n32 .tile.t42 {
	-webkit-transform: rotate(236.25deg);
	transform: rotate(236.25deg)
}

.kaleidoscope.n32 .tile.t43 {
	-webkit-transform: scale(-1, 1) rotate(236.25deg);
	transform: scale(-1, 1) rotate(236.25deg)
}

.kaleidoscope.n32 .tile.t44 {
	-webkit-transform: rotate(247.5deg);
	transform: rotate(247.5deg)
}

.kaleidoscope.n32 .tile.t45 {
	-webkit-transform: scale(-1, 1) rotate(247.5deg);
	transform: scale(-1, 1) rotate(247.5deg)
}

.kaleidoscope.n32 .tile.t46 {
	-webkit-transform: rotate(258.75deg);
	transform: rotate(258.75deg)
}

.kaleidoscope.n32 .tile.t47 {
	-webkit-transform: scale(-1, 1) rotate(258.75deg);
	transform: scale(-1, 1) rotate(258.75deg)
}

.kaleidoscope.n32 .tile.t48 {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg)
}

.kaleidoscope.n32 .tile.t49 {
	-webkit-transform: scale(-1, 1) rotate(270deg);
	transform: scale(-1, 1) rotate(270deg)
}

.kaleidoscope.n32 .tile.t50 {
	-webkit-transform: rotate(281.25deg);
	transform: rotate(281.25deg)
}

.kaleidoscope.n32 .tile.t51 {
	-webkit-transform: scale(-1, 1) rotate(281.25deg);
	transform: scale(-1, 1) rotate(281.25deg)
}

.kaleidoscope.n32 .tile.t52 {
	-webkit-transform: rotate(292.5deg);
	transform: rotate(292.5deg)
}

.kaleidoscope.n32 .tile.t53 {
	-webkit-transform: scale(-1, 1) rotate(292.5deg);
	transform: scale(-1, 1) rotate(292.5deg)
}

.kaleidoscope.n32 .tile.t54 {
	-webkit-transform: rotate(303.75deg);
	transform: rotate(303.75deg)
}

.kaleidoscope.n32 .tile.t55 {
	-webkit-transform: scale(-1, 1) rotate(303.75deg);
	transform: scale(-1, 1) rotate(303.75deg)
}

.kaleidoscope.n32 .tile.t56 {
	-webkit-transform: rotate(315deg);
	transform: rotate(315deg)
}

.kaleidoscope.n32 .tile.t57 {
	-webkit-transform: scale(-1, 1) rotate(315deg);
	transform: scale(-1, 1) rotate(315deg)
}

.kaleidoscope.n32 .tile.t58 {
	-webkit-transform: rotate(326.25deg);
	transform: rotate(326.25deg)
}

.kaleidoscope.n32 .tile.t59 {
	-webkit-transform: scale(-1, 1) rotate(326.25deg);
	transform: scale(-1, 1) rotate(326.25deg)
}

.kaleidoscope.n32 .tile.t60 {
	-webkit-transform: rotate(337.5deg);
	transform: rotate(337.5deg)
}

.kaleidoscope.n32 .tile.t61 {
	-webkit-transform: scale(-1, 1) rotate(337.5deg);
	transform: scale(-1, 1) rotate(337.5deg)
}

.kaleidoscope.n32 .tile.t62 {
	-webkit-transform: rotate(348.75deg);
	transform: rotate(348.75deg)
}

.kaleidoscope.n32 .tile.t63 {
	-webkit-transform: scale(-1, 1) rotate(348.75deg);
	transform: scale(-1, 1) rotate(348.75deg)
}

.kaleidoscope.n32 .tile .image {
	-webkit-transform: rotate(5.625deg);
	transform: rotate(5.625deg)
}

nav {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	color: #666;
	text-decoration: none;
	font-family: Helvetica;
	cursor: pointer
}

body.clean nav {
	opacity: 0
}

body.clean nav:hover {
	opacity: 1
}

nav a {
	color: #666;
	text-decoration: none
}

nav>* {
	float: left;
	padding: 0.6em;
	background-color: #eee;
	opacity: 0.8
}

nav>*:hover {
	opacity: 0.9
}

nav>*:nth-child(n+2) {
	border-left: 1px solid #666
}

nav>*:first-child {
	border-bottom-left-radius: 0.6em
}

nav>*:last-child {
	border-bottom-right-radius: 0.6em
}
